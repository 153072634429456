import { collection, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";
import { setPendingImages } from "../redux";

export async function fetchPendingImages({ dispatch }) {
  try {
    const q = query(
      collection(db, "pendingImages"),
      where("status", "!=", "processed")
    );

    const querySnapshot = await getDocs(q);
    const pendingImages = querySnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
    dispatch(setPendingImages(pendingImages));
  } catch (error) {
    console.error(error);
  }
}

import {
  AddCompanyDialog,
  InvoicePreview,
  InvoiceProducts,
  MiniCalculator,
  SelectProductDialog,
} from "../../components";
import {
  Add as AddIcon,
  Business,
  Cancel,
  Delete as DeleteIcon,
  FindReplace,
  MoreVert as MoreVertIcon,
  Person,
  Save,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TableCell as MuiTableCell,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { InfoCard, StatusChip, TotalRow, checkCompanyHealth } from "./utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Timestamp,
  deleteDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref } from "firebase/storage";

import { enqueueSnackbar } from "notistack";
import { fetchInvoices } from "../../scripts";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";

const TableCell = styled(MuiTableCell)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 0,
  },
  padding: 0,
  whiteSpace: "nowrap",
}));

const TableTextField = styled(TextField)(({ theme, changed }) => ({
  width: "100%",
  "& .MuiInputBase-root": {
    padding: 0,
    backgroundColor: changed === "true" ? "#fdeded" : "inherit",
  },
  "& .MuiInputBase-input": {
    padding: theme.spacing(1),
    textAlign: "right",
    width: "100%",
  },
}));

export default function Invoice() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [invoice, setInvoice] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [alerts, setAlerts] = useState();
  const [openSelectProductDialog, setOpenSelectProductDialog] = useState(false);
  const [originalInvoice, setOriginalInvoice] = useState(null);
  const [openAddCompanyDialog, setOpenAddCompanyDialog] = useState(false);

  const handleMenuOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemIndex(null);
  };

  useEffect(() => {
    if (id) {
      getInvoiceById(id);
    }
  }, [id]);

  useEffect(() => {
    if (invoice) {
      checkHealth({
        vendorId: invoice?.vendorId,
        customerId: invoice?.customerId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice?.customerId, invoice?.vendorId]);

  async function checkHealth({ vendorId, customerId }) {
    try {
      await checkCompanyHealth(vendorId, "vendor", setAlerts);
      await checkCompanyHealth(customerId, "customer", setAlerts);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (invoice) {
      checkCalculationError(invoice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invoice?.items,
    invoice?.total,
    invoice?.subTotal,
    invoice?.discount,
    invoice?.tax,
    invoice?.date,
    invoice?.invoiceId,
  ]);

  function checkCalculationError(invoice) {
    const { items, total, subTotal, discount, tax } = invoice;

    function setError(message) {
      setAlerts((prev) => ({
        ...prev,
        calculationError: {
          severity: "error",
          message: `Calculation error found: ${message}`,
          path: `/invoice/${invoice.id}`,
        },
      }));
      return true;
    }

    if (
      !invoice?.vendorId ||
      !invoice?.customerId ||
      !invoice?.userUid ||
      !invoice?.date ||
      !invoice?.invoiceId
    ) {
      return setError("Missing essential invoice information.");
    }

    const summaryDifference = Math.abs(total - subTotal + discount - tax);
    if (summaryDifference > 1) {
      return setError("Discrepancy in invoice summary calculations.");
    }

    const itemsTotal = items?.reduce(
      (acc, item) => acc + (Number(item?.total) || 0),
      0
    );
    if (Math.abs(total - itemsTotal) > 1) {
      return setError("Total of all items does not match invoice total.");
    }

    const itemsSubTotal = items?.reduce(
      (acc, item) => acc + (Number(item?.subTotal) || 0),
      0
    );
    if (Math.abs(subTotal - itemsSubTotal) > 1) {
      return setError("Subtotal of all items does not match invoice subtotal.");
    }

    const itemsDiscountTotal = items?.reduce(
      (acc, item) => acc + (Number(item?.discount) || 0),
      0
    );
    if (Math.abs(discount - itemsDiscountTotal) > 1) {
      return setError(
        "Total discount does not match sum of individual item discounts."
      );
    }

    const itemsTaxTotal = items?.reduce(
      (acc, item) => acc + (Number(item?.tax) || 0),
      0
    );
    if (Math.abs(tax - itemsTaxTotal) > 1) {
      return setError("Total tax does not match sum of individual item taxes.");
    }

    for (let index = 0; index < items?.length; index++) {
      const item = items[index];
      const quantity = Number(item?.quantity) || 0;
      const unitPrice = Number(item?.unitPrice) || 0;
      const itemDiscount = Number(item?.discount) || 0;
      const itemTax = Number(item?.tax) || 0;
      const itemSubTotal = Number(item?.subTotal) || 0;
      const itemTotal = Number(item?.total) || 0;

      const calculatedSubTotal = quantity * unitPrice;
      if (Math.abs(calculatedSubTotal - itemSubTotal) > 1) {
        return setError(`Subtotal calculation error in item ${index + 1}.`);
      }

      const calculatedTotal = itemSubTotal - itemDiscount + itemTax;
      if (Math.abs(calculatedTotal - itemTotal) > 1) {
        return setError(`Total calculation error in item ${index + 1}.`);
      }
    }

    // Clear the calculation error alert if no errors
    setAlerts((prev) => {
      const newAlerts = { ...prev };
      delete newAlerts.calculationError;
      return newAlerts;
    });

    return false; // No errors found
  }

  function checkItemHeath(item) {
    let error = false;
    const price = Number(item.unitPrice) || 0;
    const quantity = Number(item.quantity) || 0;
    const discount = Number(item.discount) || 0;
    const tax = Number(item.tax) || 0;
    const subTotal = Number(item.subTotal) || 0;
    const total = Number(item.total) || 0;

    if (total - subTotal - tax + discount > 1) {
      error = true;
    }
    if (price * quantity - subTotal > 1) {
      error = true;
    }

    return error;
  }

  async function getInvoiceById(id) {
    try {
      setLoading(true);
      const invoiceRef = doc(db, "cleanedInvoiceData", id);
      const docSnap = await getDoc(invoiceRef);
      if (docSnap.exists()) {
        const invoiceData = { id: docSnap.id, ...docSnap.data() };
        const calculatedInvoice = calculateInvoice(invoiceData);

        setInvoice(calculatedInvoice);
        // setInvoice(invoiceData);
        setOriginalInvoice(invoiceData); // Deep copy

        if (invoiceData.filePath) {
          const storageRef = ref(storage, invoiceData.filePath);
          const url = await getDownloadURL(storageRef);
          setInvoice((prev) => ({ ...prev, imgUrl: url }));
        }
      } else {
        setInvoice(null);
      }
    } catch (error) {
      console.error("Error fetching invoice:", error);
    } finally {
      setLoading(false);
    }
  }

  const calculateInvoice = (invoiceData) => {
    const calculatedItems = invoiceData.items.map((item) => {
      const quantity = Number(item.quantity) || 0;
      const unitPrice = Number(item.unitPrice) || 0;
      const discountRate = Number(item.discountRate) || 0;
      const taxRate = Number(item.taxRate) || 0;

      const subTotal = quantity * unitPrice;
      const discount = (discountRate / 100) * subTotal;
      const tax = (taxRate / 100) * (subTotal - discount);
      const total = subTotal - discount + tax;

      return {
        ...item,
        quantity,
        unitPrice,
        discountRate,
        taxRate,
        subTotal: Number(subTotal),
        discount: Number(discount),
        tax: Number(tax),
        total: Number(total),
      };
    });

    const subTotal = calculatedItems.reduce(
      (sum, item) => sum + item.subTotal,
      0
    );
    const totalDiscount = calculatedItems.reduce(
      (sum, item) => sum + item.discount,
      0
    );
    const totalTax = calculatedItems.reduce((sum, item) => sum + item.tax, 0);
    const total = calculatedItems.reduce((sum, item) => sum + item.total, 0);

    return {
      ...invoiceData,
      items: calculatedItems,
      subTotal: Number(subTotal),
      discount: Number(totalDiscount),
      tax: Number(totalTax),
      total: Number(total),
    };
  };

  const hasValueChanged = (path, value) => {
    if (!originalInvoice) return false;
    const originalValue = path
      .split(".")
      .reduce((obj, key) => obj && obj[key], originalInvoice);

    return (originalValue !== value).toString();
  };

  const handleSave = async () => {
    try {
      if (Object.keys(alerts || {})?.length > 0) {
        enqueueSnackbar("Please fix all errors before saving.", {
          variant: "error",
        });
        return;
      }

      await updateDoc(doc(db, "cleanedInvoiceData", id), {
        ...invoice,
        status: "admin-approved",
        calculationError: checkCalculationError(invoice),
      });
      await getInvoiceById(id);
      enqueueSnackbar("Invoice approved", { variant: "success" });
    } catch (error) {
      console.error("Error approving invoice:", error);
      enqueueSnackbar("Error approving invoice", { variant: "error" });
    }
  };

  const handleItemChange = (index, field, value) => {
    const newItems = [...invoice.items];
    newItems[index][field] = value;

    // Recalculate item totals
    const item = newItems[index];
    item.subTotal = item.quantity * item.unitPrice;
    item.discount = (item.discountRate / 100) * item.subTotal;
    item.tax = (item.taxRate / 100) * (item.subTotal - item.discount);
    item.total = item.subTotal - item.discount + item.tax;

    setInvoice({ ...invoice, items: newItems });

    // Recalculate invoice totals
    const subTotal = newItems.reduce((sum, item) => sum + item.subTotal, 0);
    const totalDiscount = newItems.reduce(
      (sum, item) => sum + item.discount,
      0
    );
    const totalTax = newItems.reduce((sum, item) => sum + item.tax, 0);
    const total = newItems.reduce((sum, item) => sum + item.total, 0);

    setInvoice((prev) => ({
      ...prev,
      subTotal,
      discount: totalDiscount,
      tax: totalTax,
      total,
    }));
  };

  const handleAddItem = (index, value) => {
    const newItem = {
      productId: value?.productId || "",
      productName: value?.productName || "",
      quantity: 0,
      unitPrice: 0,
      discountRate: 0,
      discount: 0,
      taxRate: 0,
      tax: 0,
      subTotal: 0,
      total: 0,
    };
    const newItems = [
      ...invoice.items.slice(0, index + 1),
      newItem,
      ...invoice.items.slice(index + 1),
    ];
    setInvoice({ ...invoice, items: newItems });
    setOpenSelectProductDialog(false);
  };
  const handleReplaceItem = (index, value) => {
    const newItems = [...invoice.items];

    newItems[index] = {
      ...newItems[index],
      productId: value?.productId || "",
      productName: value?.productName || "",
    };
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      items: newItems,
    }));

    setOpenSelectProductDialog(false);
  };

  const handleRemoveItem = (index) => {
    const newItems = invoice.items.filter((_, i) => i !== index);
    setInvoice({ ...invoice, items: newItems });
    recalculateInvoiceTotals(newItems);
    handleMenuClose();
  };

  const recalculateInvoiceTotals = (items) => {
    const subTotal = items.reduce((sum, item) => sum + item.subTotal, 0);
    const totalDiscount = items.reduce((sum, item) => sum + item.discount, 0);
    const totalTax = items.reduce((sum, item) => sum + item.tax, 0);
    const total = items.reduce((sum, item) => sum + item.total, 0);

    setInvoice((prev) => ({
      ...prev,
      subTotal,
      discount: totalDiscount,
      tax: totalTax,
      total,
    }));
  };

  async function deleteInvoice() {
    try {
      await deleteDoc(doc(db, "cleanedInvoiceData", id));
      navigate(-1);
      fetchInvoices({ dispatch });
      enqueueSnackbar("invoiceDeleted", { variant: "success" });
    } catch (error) {
      console.error(error);
    }
  }

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!invoice) {
    return <Typography>Invoice not found</Typography>;
  }

  return (
    <Box sx={{ padding: 2, backgroundColor: "#f5f5f7" }}>
      <Paper elevation={2} sx={{ p: 4, borderRadius: 2, mb: 4 }}>
        {Object.values(alerts || {})?.map((alert, i) => {
          return (
            <Link key={i} to={alert.path} style={{ textDecoration: "none" }}>
              <Alert
                severity={alert.severity}
                sx={{
                  mb: 2,
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 0.8,
                  },
                }}
              >
                {alert.message}
              </Alert>
            </Link>
          );
        })}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box>
            <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
              Invoice
            </Typography>
            <StatusChip status={invoice?.status} />
            <StatusChip
              status={
                invoice?.calculationError
                  ? "Calculation Error"
                  : "Calculation Passed"
              }
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
              sx={{ mr: 2 }}
              onClick={deleteInvoice}
            >
              Delete
            </Button>

            <Button
              variant="contained"
              color="warning"
              startIcon={<Cancel />}
              disabled={invoice.status === "rejected"}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              color="success"
              startIcon={<Save />}
              onClick={handleSave}
              sx={{ ml: 2 }}
              disabled={invoice.status === "approved"}
            >
              Save
            </Button>
          </Box>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Grid container spacing={4}>
            {expanded && (
              <Grid item xs={12} md={6}>
                <InvoicePreview
                  img={invoice?.imgUrl}
                  contentType={invoice?.contentType}
                  sx={{ width: "100%", height: "auto", borderRadius: 1 }}
                />
              </Grid>
            )}
            <Grid item xs={12} md={expanded ? 6 : 12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <TextField
                    label="Date"
                    type="date"
                    size="small"
                    value={
                      invoice.date
                        ? new Date(invoice.date.seconds * 1000)
                            .toISOString()
                            .split("T")[0]
                        : ""
                    }
                    onChange={(e) => {
                      const timestamp = Timestamp.fromDate(
                        new Date(e.target.value)
                      );

                      setInvoice({
                        ...invoice,
                        date: {
                          seconds: timestamp.seconds,
                          nanoseconds: timestamp.nanoseconds,
                        },
                      });
                    }}
                    sx={{ mt: 1 }}
                  />
                  <TextField
                    label="Invoice ID"
                    value={invoice.invoiceId}
                    onChange={(e) =>
                      setInvoice({ ...invoice, invoiceId: e.target.value })
                    }
                    variant="outlined"
                    size="small"
                    fullWidth
                    sx={{ my: 2 }}
                  />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    sx={{ mr: 2, cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    <InvoicePreview
                      img={invoice?.imgUrl}
                      contentType={invoice?.contentType}
                      sx={{ width: "100px", height: "100px", borderRadius: 1 }}
                    />
                  </Box>
                </Box>
              </Box>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <InfoCard
                    title={invoice.vendorName}
                    taxId={invoice.vendorId}
                    icon={<Business />}
                    onTaxIdChange={(value) =>
                      setInvoice({
                        ...invoice,
                        vendorId: value.taxId,
                        vendorName: value.name,
                      })
                    }
                    addCompany={() => setOpenAddCompanyDialog(true)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InfoCard
                    title={invoice.customerName}
                    taxId={invoice.customerId}
                    icon={<Person />}
                    onTaxIdChange={(value) =>
                      setInvoice({
                        ...invoice,
                        customerId: value.taxId,
                        customerName: value.name,
                      })
                    }
                  />
                </Grid>
              </Grid>
              <TableContainer
                component={Paper}
                elevation={0}
                sx={{ mt: 4, backgroundColor: "transparent" }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell sx={{ minWidth: "40px" }}>Quantity</TableCell>
                      <TableCell sx={{ minWidth: "60px" }}>
                        Unit Price
                      </TableCell>
                      <TableCell sx={{ minWidth: "40px" }}>
                        D. Rate (%)
                      </TableCell>
                      <TableCell sx={{ minWidth: "60px" }}>Discount</TableCell>
                      <TableCell sx={{ minWidth: "40px" }}>
                        Tax Rate (%)
                      </TableCell>
                      <TableCell sx={{ minWidth: "60px" }}>Tax</TableCell>
                      <TableCell sx={{ minWidth: "60px" }}>Subtotal</TableCell>
                      <TableCell sx={{ minWidth: "60px" }}>Total</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoice.items?.map((item, index) => {
                      const itemCalculationError = checkItemHeath(item);
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            backgroundColor: itemCalculationError
                              ? "#fdeded"
                              : "",
                          }}
                        >
                          <TableCell>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography>
                                {index + 1}. {item.productName}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={(event) =>
                                  handleMenuOpen(event, index)
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: 0,
                            }}
                            align="right"
                          >
                            <TableTextField
                              value={item?.quantity || 0}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "quantity",
                                  e.target.value
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ min: 0 }}
                              changed={hasValueChanged(
                                `items.${index}.quantity`,
                                item?.quantity
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item?.unitPrice || 0}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "unitPrice",
                                  e.target.value || 0
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ min: 0 }}
                              changed={hasValueChanged(
                                `items.${index}.unitPrice`,
                                item?.unitPrice
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item.discountRate || 0}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "discountRate",
                                  e.target.value || 0
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ step: "0.1", min: 0, max: 100 }}
                              changed={hasValueChanged(
                                `items.${index}.discountRate`,
                                item?.discountRate
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item?.discount || 0}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "discountRate",
                                  (e.target.value / item?.subTotal) * 100
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ step: "0.01", min: 0 }}
                              changed={hasValueChanged(
                                `items.${index}.discount`,
                                item?.discount
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item?.taxRate}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "taxRate",
                                  e.target.value || 0
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ step: "0.1", min: 0, max: 100 }}
                              changed={hasValueChanged(
                                `items.${index}.taxRate`,
                                item?.taxRate
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item?.tax}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "tax",
                                  e.target.value || 0
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ step: "0.01", min: 0 }}
                              changed={hasValueChanged(
                                `items.${index}.tax`,
                                item?.tax
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item?.subTotal}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "subTotal",
                                  e.target.value || 0
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ step: "0.01", min: 0 }}
                              changed={hasValueChanged(
                                `items.${index}.subTotal`,
                                item?.subTotal
                              )}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TableTextField
                              value={item?.total}
                              onChange={(e) =>
                                handleItemChange(
                                  index,
                                  "total",
                                  e.target.value || 0
                                )
                              }
                              type="number"
                              size="small"
                              inputProps={{ step: "0.01", min: 0 }}
                              changed={hasValueChanged(
                                `items.${index}.total`,
                                item?.total
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <SelectProductDialog
                open={openSelectProductDialog}
                onClose={() => setOpenSelectProductDialog(false)}
                onAddProduct={(v) => handleAddItem(selectedItemIndex, v)}
                onReplaceProduct={(v) =>
                  handleReplaceItem(selectedItemIndex, v)
                }
              />

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem
                  onClick={() => {
                    setOpenSelectProductDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  <AddIcon fontSize="small" sx={{ mr: 1 }} />
                  Add Line
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenSelectProductDialog(true);
                    setAnchorEl(null);
                  }}
                >
                  <FindReplace fontSize="small" sx={{ mr: 1 }} />
                  Replace Line
                </MenuItem>
                <MenuItem onClick={() => handleRemoveItem(selectedItemIndex)}>
                  <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                  Delete Line
                </MenuItem>
              </Menu>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}
              >
                <MiniCalculator />

                <Card
                  elevation={0}
                  sx={{
                    backgroundColor: "#f0f0f2",
                    p: 3,
                    borderRadius: 2,
                    maxWidth: "500px",
                    width: "100%",
                  }}
                >
                  <TotalRow
                    title="Subtotal"
                    value={invoice.subTotal || 0}
                    toolTip={originalInvoice.subTotal || 0}
                    onValueChange={(value) =>
                      setInvoice({ ...invoice, subTotal: value })
                    }
                    changed={hasValueChanged(`subTotal`, invoice?.subTotal)}
                  />
                  <TotalRow
                    title="Total Discount"
                    value={invoice.discount || 0}
                    toolTip={originalInvoice.discount || 0}
                    onValueChange={(value) =>
                      setInvoice({ ...invoice, discount: value })
                    }
                    changed={hasValueChanged(`discount`, invoice?.discount)}
                  />
                  <TotalRow
                    title="Total Tax"
                    value={invoice.tax || 0}
                    toolTip={originalInvoice.tax || 0}
                    onValueChange={(value) =>
                      setInvoice({ ...invoice, tax: value })
                    }
                    changed={hasValueChanged(`tax`, invoice?.tax)}
                  />
                  <Box sx={{ my: 2, borderTop: "1px solid #e0e0e0", pt: 2 }}>
                    <TotalRow
                      title="Total"
                      value={invoice.total || 0}
                      toolTip={originalInvoice.total || 0}
                      onValueChange={(value) =>
                        setInvoice({ ...invoice, total: value })
                      }
                      bold={true}
                      changed={hasValueChanged(`total`, invoice?.total)}
                    />
                  </Box>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <InvoiceProducts
        invoice={invoice}
        setAlerts={setAlerts}
        setInvoice={setInvoice}
        calculateInvoice={calculateInvoice}
      />
      <AddCompanyDialog
        open={openAddCompanyDialog}
        onClose={() => setOpenAddCompanyDialog(false)}
      />
    </Box>
  );
}

import { Alert, Box, Link } from "@mui/material";

import React from "react";
import { useSelector } from "react-redux";

export default function Home() {
  const {
    procurementItemsToDelete,
    procurementProductsToDelete,
    analyticsUnknownCategories,
  } = useSelector((state) => state.dbCheck);

  const handleLinkClick = (event, link) => {
    if (event.button === 1 || event.ctrlKey || event.metaKey) {
      window.open(link, "_blank");
      event.preventDefault();
    } else {
      window.location.href = link;
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {Object.keys(procurementItemsToDelete || {}).length > 0 ? (
        Object.keys(procurementItemsToDelete || {}).map((item, index) => (
          <Alert key={index} severity="error">
            Delete item:
            <Link
              href="#"
              onClick={(e) =>
                handleLinkClick(e, procurementItemsToDelete[item])
              }
              sx={{ cursor: "pointer", ml: 1 }}
            >
              {item}
            </Link>
          </Alert>
        ))
      ) : (
        <Alert severity="success">All checked items are present</Alert>
      )}
      {Object.keys(procurementProductsToDelete || {}).length > 0 ? (
        Object.keys(procurementProductsToDelete || {}).map((item, index) => (
          <Alert key={index} severity="error">
            Delete product:
            <Link
              href="#"
              onClick={(e) =>
                handleLinkClick(e, procurementProductsToDelete[item])
              }
              sx={{ cursor: "pointer", ml: 1 }}
            >
              {item}
            </Link>
          </Alert>
        ))
      ) : (
        <Alert severity="success">All products are present</Alert>
      )}
      {analyticsUnknownCategories.length > 0 ? (
        analyticsUnknownCategories.map((item, index) => (
          <Alert key={index} severity="error">
            Delete unknown category:
            <Link
              href="#"
              onClick={(e) => handleLinkClick(e, item)}
              sx={{ cursor: "pointer", ml: 1 }}
            >
              {item}
            </Link>
          </Alert>
        ))
      ) : (
        <Alert severity="success">All checked items are present</Alert>
      )}
    </Box>
  );
}

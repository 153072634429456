import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { setProcurementItemsToDelete } from "../redux";

export async function checkProcurementItemsToDelete({ dispatch }) {
  try {
    const checkItems = httpsCallable(functions, "checkProcurementItems");
    const result = await checkItems();
    const data = result.data;
    dispatch(setProcurementItemsToDelete(data?.itemLinks));
  } catch (error) {
    console.error("Error checking procurement items:", error);
  }
}

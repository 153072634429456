import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import { setProcurementProductsToDelete } from "../redux";

export async function checkProcurementProductsConsistency({ dispatch }) {
  try {
    const checkItems = httpsCallable(
      functions,
      "checkProcurementProductsConsistency"
    );
    const result = await checkItems();
    const data = result.data;
    dispatch(setProcurementProductsToDelete(data?.missingProducts));
  } catch (error) {
    console.error("Error checking procurement items:", error);
  }
}

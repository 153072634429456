import {
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { addDoc, collection, getDocs } from "firebase/firestore";

import AddIcon from "@mui/icons-material/Add";
import { createCurrency } from "../../utils";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";

const styles = {
  container: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
  },
  table: {
    minWidth: 650,
  },
};

export default function Recipe() {
  const [recipes, setRecipes] = useState([]);
  const { recipe, customerId } = useParams();
  console.log({ recipe, customerId });

  useEffect(() => {
    fetchRecipes();
  }, []);

  const fetchRecipes = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "recipes"));
      const recipeList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipes(recipeList);
    } catch (error) {
      console.error("Error fetching recipes:", error);
    }
  };

  return (
    <Container sx={styles.container}>
      <Typography variant="h4" gutterBottom>
        Recipe
      </Typography>
    </Container>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  procurementItemsToDelete: {},
  procurementProductsToDelete: {},
  analyticsUnknownCategories: [],
};

const dbCheckSlice = createSlice({
  name: "dbCheck",
  initialState,
  reducers: {
    setProcurementItemsToDelete: (state, action) => {
      state.procurementItemsToDelete = action.payload;
    },
    setProcurementProductsToDelete: (state, action) => {
      state.procurementProductsToDelete = action.payload;
    },
    setAnalyticsUnknownCategories: (state, action) => {
      state.analyticsUnknownCategories = action.payload;
    },
  },
});

export const {
  setProcurementItemsToDelete,
  setProcurementProductsToDelete,
  setAnalyticsUnknownCategories,
} = dbCheckSlice.actions;

export default dbCheckSlice.reducer;

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";

import { db } from "../../firebase";

export default function DeleteProductDialog({ open, onClose, product }) {
  const [isInUse, setIsInUse] = useState(false);
  const [usageDetails, setUsageDetails] = useState({
    invoices: [],
    offers: [],
  });
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (product?.id) {
      checkProductInUse(product.id);
    }
  }, [product, db]);

  async function checkProductInUse(productId) {
    try {
      const purchaseQuery = query(
        collection(db, "purchases"),
        where("productId", "==", productId)
      );

      const offerQuery = query(
        collection(db, "offers"),
        where("productId", "==", productId)
      );

      const [purchaseSnapshot, offerSnapshot] = await Promise.all([
        getDocs(purchaseQuery),
        getDocs(offerQuery),
      ]);

      const invoices = purchaseSnapshot.docs.map((doc) => doc.data().invoiceId);
      const offers = offerSnapshot.docs.map((doc) => doc.id);

      if (!purchaseSnapshot.empty || !offerSnapshot.empty) {
        setIsInUse(true);
        setUsageDetails({ invoices, offers });
      } else {
        setIsInUse(false);
        setUsageDetails({ invoices: [], offers: [] });
      }
    } catch (error) {
      console.error("Error checking product usage:", error);
      setError("Failed to check product usage. Please try again.");
    }
  }

  const handleDelete = async () => {
    if (!isInUse && product?.id) {
      setIsDeleting(true);
      setError(null);
      try {
        await deleteDoc(doc(db, "products", product.id));
        onClose();
      } catch (error) {
        console.error("Error deleting product:", error);
        setError("Failed to delete product. Please try again.");
      } finally {
        setIsDeleting(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Product</DialogTitle>

      <DialogContent>
        <p>name: {product?.name}</p>
        <p>productName: {product?.productName}</p>
        <br />
        {error && <p style={{ color: "red" }}>{error}</p>}
        {isInUse ? (
          <>
            <p>This product is in use and cannot be deleted. Details:</p>
            {usageDetails.invoices.length > 0 && (
              <>
                <p>Invoices:</p>
                <List>
                  {usageDetails.invoices.map((invoiceId, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={`Invoice ID: ${invoiceId}`} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
            {usageDetails.offers.length > 0 && (
              <>
                <p>Offers:</p>
                <List>
                  {usageDetails.offers.map((offerId, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={`Offer ID: ${offerId}`} />
                    </ListItem>
                  ))}
                </List>
              </>
            )}
          </>
        ) : (
          <p>Are you sure you want to delete this product?</p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isDeleting}>
          Cancel
        </Button>
        <Button
          onClick={handleDelete}
          disabled={isInUse || isDeleting}
          color="error"
        >
          {isDeleting ? <CircularProgress size={24} /> : "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { collectionGroup, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";

export const fetchCustomerProducts = async ({
  setLoading,
  customerId,
  setBuyerProducts,
}) => {
  try {
    setLoading(true);
    const buyerProductsQuery = query(
      collectionGroup(db, "buyers"),
      where("buyerId", "==", customerId),
      where("category", "!=", null)
    );
    const querySnapshot = await getDocs(buyerProductsQuery);
    const fetchedProducts = querySnapshot.docs.map((doc, i) => ({
      id: i,
      productId: doc.ref.parent.parent.id,
      ...doc.data(),
    }));
    console.log({ fetchedProducts });
    setBuyerProducts(fetchedProducts);
  } catch (error) {
    console.error("Error fetching supplier products:", error);
  } finally {
    setLoading(false);
  }
};

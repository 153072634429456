import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { DataGrid } from "@mui/x-data-grid";
import InfoCard from "../../components/InfoCard";
import { createCurrency } from "../../utils";
import { db } from "../../firebase";

const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;

const CellWithBackground = ({ value, thresholds, children }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor:
        value > thresholds.high || value < thresholds.low ? "#ffdcdc" : "",
    }}
  >
    <Typography sx={{ fontSize: 14 }}>{children}</Typography>
  </Box>
);

export default function Vendor() {
  const navigate = useNavigate();
  const { supplierId } = useParams();
  const [supplierProducts, setSupplierProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supplierDetails, setSupplierDetails] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");

  useEffect(() => {
    const fetchSupplierProducts = async () => {
      try {
        setLoading(true);
        const supplierProductsQuery = query(
          collectionGroup(db, "sellers"),
          where("sellerId", "==", supplierId)
        );
        const querySnapshot = await getDocs(supplierProductsQuery);
        const fetchedProducts = querySnapshot.docs.map((doc, i) => ({
          id: i,
          productId: doc.ref.parent.parent.id,
          ...doc.data(),
        }));
        setSupplierProducts(fetchedProducts);
      } catch (error) {
        console.error("Error fetching supplier products:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchSupplierDetails = async () => {
      try {
        const docRef = doc(db, "companyDetails", supplierId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setSupplierDetails(docSnap.data());
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching supplier details:", error);
      }
    };

    if (supplierId) {
      fetchSupplierProducts();
      fetchSupplierDetails();
    }
  }, [supplierId]);

  const columns = useMemo(
    () => [
      { field: "productName", headerName: "Product Name", flex: 1 },
      {
        field: "highPrice",
        headerName: "High Price",
        type: "number",
        flex: 1,
        valueFormatter: (value) => createCurrency(value).format(),
      },
      {
        field: "lowPrice",
        headerName: "Low Price",
        type: "number",
        flex: 1,
        valueFormatter: (value) => createCurrency(value).format(),
      },
      {
        field: "priceDifference",
        headerName: "Price Difference",
        type: "number",
        flex: 1,
        valueFormatter: ({ value }) =>
          value != null ? formatPercentage(value) : "N/A",
        renderCell: ({ row }) => {
          const value = (row?.priceDifference || 0) * 100;
          return (
            <CellWithBackground
              value={value}
              thresholds={{ high: 100, low: 5 }}
            >
              {formatPercentage(row?.priceDifference || 0)}
            </CellWithBackground>
          );
        },
      },
      {
        field: "totalQuantity",
        headerName: "Total Quantity",
        type: "number",
        flex: 1,
        valueFormatter: (value) => value?.toFixed(2),
      },
      {
        field: "totalQuantityWeight",
        headerName: "Quantity Weight",
        type: "number",
        flex: 1,
        valueFormatter: (value) => formatPercentage(value || 0),
      },
      {
        field: "lastPrice",
        headerName: "Last Price",
        type: "number",
        flex: 1,
        valueFormatter: (value) => createCurrency(value).format(),
      },
      {
        field: "category",
        headerName: "Category",
        flex: 1,
      },
      {
        field: "subCategory",
        headerName: "Sub Category",
        flex: 1,
      },
    ],
    []
  );

  const filteredProducts = useMemo(() => {
    return supplierProducts.filter(
      (product) =>
        product.productName.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (selectedCategory === "" || product.category === selectedCategory) &&
        (selectedSubCategory === "" ||
          product.subCategory === selectedSubCategory)
    );
  }, [supplierProducts, searchTerm, selectedCategory, selectedSubCategory]);

  const categories = [
    ...new Set(supplierProducts.map((product) => product.category)),
  ];
  const subCategories = useMemo(() => {
    const allSubCategories = supplierProducts
      .filter(
        (product) =>
          selectedCategory === "" || product.category === selectedCategory
      )
      .map((product) => product.subCategory);
    return [...new Set(allSubCategories)];
  }, [supplierProducts, selectedCategory]);

  const totalProducts = filteredProducts.length;
  const productsPriceDiffLessThan5Percent = filteredProducts.filter(
    (product) => (product.priceDifference || 0) < 0.05
  ).length;

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    setSelectedSubCategory(""); // Reset subcategory when category changes
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {supplierDetails?.name || `Supplier: ${supplierId}`}
      </Typography>

      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} sm={6}>
          <InfoCard
            title="Total Products"
            value={totalProducts}
            description="Number of products"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InfoCard
            title="Products Price Diff < 5%"
            value={productsPriceDiffLessThan5Percent}
            description="Products with price difference less than 5%"
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          label="Search Products"
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "100%",
          }}
        />
        <Select
          value={selectedCategory}
          onChange={handleCategoryChange}
          displayEmpty
          size="small"
        >
          <MenuItem value="">All Categories</MenuItem>
          {categories.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Select
          value={selectedSubCategory}
          onChange={(e) => setSelectedSubCategory(e.target.value)}
          displayEmpty
          size="small"
          disabled={!selectedCategory}
        >
          <MenuItem value="">All Sub Categories</MenuItem>
          {subCategories.map((subCategory) => (
            <MenuItem key={subCategory} value={subCategory}>
              {subCategory}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <DataGrid
        rows={filteredProducts}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 25]}
        disableSelectionOnClick
        onRowClick={(params) =>
          navigate(`/purchase-analytics/${params.row.productName}/country`)
        }
        initialState={{
          sorting: {
            sortModel: [{ field: "priceDifference", sort: "desc" }],
          },
        }}
        loading={loading}
        getRowId={(row) => row.id}
      />
    </Box>
  );
}

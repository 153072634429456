import { Card, CardContent, Typography } from "@mui/material";

export default function InfoCard({ title, value }) {
  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardContent>
        <Typography variant="h6" component="div">
          {title}
        </Typography>
        <Typography variant="h4" component="div">
          {value}
        </Typography>
      </CardContent>
    </Card>
  );
}

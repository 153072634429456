import companiesReducer from "./companiesSlice";
// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import dbCheckSliceReducer from "./dbCheckSlice";
import invoicesReducer from "./invoicesSlice";

export const store = configureStore({
  reducer: {
    companies: companiesReducer,
    invoices: invoicesReducer,
    dbCheck: dbCheckSliceReducer,
    // Add other reducers here as needed
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
});

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  Download as DownloadIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import { SearchProducts } from "../../components";
import { db } from "../../firebase"; // Adjust the import path as necessary

const apiKey = "e811f65facc048d1bd4f0e28121cac07";
const endpoint = "https://api.bing.microsoft.com/v7.0/images/search";

function sanitizeDocumentId(id) {
  return id.replace(/\//g, "-").replace(/[^a-zA-Z0-9-_]/g, "_");
}

const DropZone = ({ children, ...props }) => (
  <Box
    sx={{
      border: "2px dashed grey",
      borderRadius: 2,
      p: 2,
      textAlign: "center",
      cursor: "pointer",
    }}
    {...props}
  >
    {children}
  </Box>
);

const EditProductDialog = ({
  open,
  onClose,
  product,
  relatedProducts,
  setAlerts,
}) => {
  const [editFields, setEditFields] = useState({});
  const [originalFields, setOriginalFields] = useState({});
  const [imageSearchResults, setImageSearchResults] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (product) {
      const fields = {
        genericName: product.genericName,
        productName: product.productName,
        taxRate: product.taxRate,
        unit: product.unit,
        unitWeight: product.unitWeight,
        category: product.category,
        subCategory: product.subCategory,
        imgUrl: product.imgUrl,
        skipPurchaseTracking: product.skipPurchaseTracking || false,
      };
      setEditFields(fields);
      setOriginalFields(fields);
    }
  }, [product]);

  const handleEditFieldChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setEditFields({ ...editFields, [e.target.name]: value });
  };

  const handleEditSubmit = async () => {
    try {
      const changes = {};
      for (const key in editFields) {
        if (editFields[key] !== originalFields[key]) {
          changes[key] = editFields[key];
        }
      }

      if (Object.keys(changes).length === 0) {
        onClose(false);
        return;
      }

      const updatePromises = relatedProducts.map((product) =>
        updateDoc(doc(db, "products", product.id), changes)
      );
      await Promise.all(updatePromises);
      onClose(true); // Pass true to indicate successful update
    } catch (error) {
      console.error("Error updating products:", error);
      setAlerts((prev) => ({
        ...prev,
        updateError: {
          severity: "error",
          message: `Failed to update products`,
        },
      }));
    }
  };

  const searchImages = async () => {
    try {
      const response = await fetch(
        `${endpoint}?q=${encodeURIComponent(editFields.productName)}&count=6`,
        {
          headers: { "Ocp-Apim-Subscription-Key": apiKey },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setImageSearchResults(data.value);
    } catch (error) {
      console.error("Error searching for images:", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, `productImages/${file.name}`);
      await uploadBytes(imageRef, file);
      const downloadUrl = await getDownloadURL(imageRef);
      setSelectedImage(downloadUrl);
      setEditFields((prev) => ({ ...prev, imgUrl: downloadUrl }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const handleImageSelect = useCallback((imageUrl) => {
    setSelectedImage(imageUrl);
  }, []);

  const handleImageDownload = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = sanitizeDocumentId(editFields.productName) + ".jpg";
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  function handleCopyProduct(product) {
    const copyProduct = {
      genericName: product.genericName,
      productName: product.productName,
      taxRate: product.taxRate,
      unit: product.unit,
      unitWeight: product.unitWeight,
      category: product.category,
      subCategory: product.subCategory,
      imgUrl: product.imgUrl,
    };
    setEditFields(copyProduct);
  }

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="lg" fullWidth>
      <DialogTitle>Edit Related Products</DialogTitle>
      <DialogContent>
        <Typography variant="body2" sx={{ mb: 2 }}>
          Edit the fields below to update all related products with the same
          product name.
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {Object.entries(editFields).map(([field, value]) =>
              field === "skipPurchaseTracking" ? (
                <FormControlLabel
                  key={field}
                  control={
                    <Checkbox
                      checked={value}
                      onChange={handleEditFieldChange}
                      name={field}
                    />
                  }
                  label="Skip Purchase Tracking"
                />
              ) : (
                <TextField
                  key={field}
                  name={field}
                  label={field.charAt(0).toUpperCase() + field.slice(1)}
                  value={value || ""}
                  onChange={handleEditFieldChange}
                  fullWidth
                  margin="normal"
                />
              )
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ mb: 2, width: "100%" }}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={searchImages}
                fullWidth
              >
                Search Images
              </Button>
            </Box>
            <Box sx={{ mb: 2 }}>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
              <DropZone
                onDragOver={(e) => {
                  e.preventDefault();
                  setIsDragging(true);
                }}
                onDragLeave={(e) => {
                  e.preventDefault();
                  setIsDragging(false);
                }}
                onDrop={handleFileDrop}
                onClick={() => fileInputRef.current.click()}
                style={{
                  backgroundColor: isDragging
                    ? "rgba(0, 0, 0, 0.1)"
                    : "transparent",
                }}
              >
                <Typography>
                  Drag and drop an image here, or click to select a file
                </Typography>
              </DropZone>
            </Box>
            <SearchProducts
              onSelect={(product) => handleCopyProduct(product)}
            />
            <Grid container spacing={1}>
              {imageSearchResults.map((image, index) => (
                <Grid item xs={4} key={index}>
                  <img
                    src={image.thumbnailUrl}
                    alt={`Search result ${index + 1}`}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      border:
                        selectedImage === image.contentUrl
                          ? "2px solid blue"
                          : "none",
                    }}
                    onClick={() => handleImageSelect(image.contentUrl)}
                  />
                  <IconButton
                    onClick={() => handleImageDownload(image.contentUrl)}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                {Object.keys(editFields).map((field) => (
                  <TableCell key={field}>
                    {field === "skipPurchaseTracking"
                      ? "Skip Tracking"
                      : field.charAt(0).toUpperCase() + field.slice(1)}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {relatedProducts.map((product) => (
                <TableRow key={product.id}>
                  <TableCell>
                    <Avatar
                      src={product.imgUrl || "/placeholder-image.jpg"}
                      alt={product.productName}
                      variant="square"
                      sx={{ width: 40, height: 40 }}
                    />
                  </TableCell>
                  {Object.keys(editFields).map((field) => (
                    <TableCell key={field}>
                      {field === "skipPurchaseTracking"
                        ? product[field]
                          ? "Yes"
                          : "No"
                        : product[field] || "N/A"}
                    </TableCell>
                  ))}
                  <TableCell>{product?.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)}>Cancel</Button>
        <Button onClick={handleEditSubmit} variant="contained">
          Save Changes for All Related Products
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditProductDialog;

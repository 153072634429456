import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyList: null,
};

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    setCompanyList: (state, action) => {
      state.companyList = action.payload;
    },
  },
});

export const { setCompanyList } = companiesSlice.actions;

export default companiesSlice.reducer;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";

import { SearchProducts } from "..";

export default function SelectProductDialog({
  open,
  onClose,
  onAddProduct,
  onReplaceProduct,
}) {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductSelect = (product) => {
    setSelectedProduct(product);
  };

  const handleAddProduct = () => {
    if (selectedProduct) {
      // Here you can handle saving the selected product
      console.log("Selected product:", {
        productId: selectedProduct?.id,
        productName: selectedProduct?.productName,
      });
      onAddProduct({
        productId: selectedProduct?.id,
        productName: selectedProduct?.productName,
      });
    }
  };

  const handleReplaceProduct = () => {
    if (selectedProduct) {
      // Here you can handle saving the selected product
      console.log("Selected product:", {
        productId: selectedProduct?.id,
        productName: selectedProduct?.productName,
      });
      onReplaceProduct({
        productId: selectedProduct?.id,
        productName: selectedProduct?.productName,
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Select Product</DialogTitle>
      <DialogContent>
        <SearchProducts onSelect={(product) => handleProductSelect(product)} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          color="warning"
          onClick={handleReplaceProduct}
          disabled={!selectedProduct}
        >
          Replace Product
        </Button>
        <Button
          variant="contained"
          onClick={handleAddProduct}
          disabled={!selectedProduct}
        >
          Add Product
        </Button>
      </DialogActions>
    </Dialog>
  );
}

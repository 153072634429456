import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { doc, getDoc } from "firebase/firestore";

import { Add } from "@mui/icons-material";
import { db } from "../../firebase";
import { liteClient } from "algoliasearch/lite";
import { useState } from "react";

export async function checkCompanyHealth(id, type, setAlerts) {
  try {
    const docRef = doc(db, "companyDetails", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const companyData = { id: docSnap.id, ...docSnap.data() };
      const missingFields = [];

      if (!companyData.imgUrl) missingFields.push("Image URL");
      if (!companyData.country) missingFields.push("Country");
      if (!companyData.city) missingFields.push("City");
      if (!companyData.taxid) missingFields.push("taxid");
      if (!companyData.type) missingFields.push("type");
      if (!companyData.verified) missingFields.push("verified");

      if (missingFields.length > 0) {
        setAlerts((prev) => ({
          ...prev,
          [type]: {
            severity: "warning",
            path: `/company/${id}`,
            message: `Company information incomplete. Missing: ${missingFields.join(
              ", "
            )}`,
          },
        }));
      } else {
        // Clear the alert if all required fields are present
        setAlerts((prev) => {
          const newAlerts = { ...prev };
          delete newAlerts[type];
          return newAlerts;
        });
      }
    } else {
      setAlerts((prev) => ({
        ...prev,
        [type]: {
          severity: "error",
          message: `Company not found ${type}`,
          path: `/company/${id}`,
        },
      }));
    }
  } catch (error) {
    console.error(error);
    setAlerts((prev) => ({
      ...prev,
      [type]: {
        severity: "error",
        message: "Error checking company health",
        path: `/company/${id}`,
      },
    }));
  }
}

export const TotalRow = ({
  title,
  value,
  onValueChange,
  bold = false,
  changed,
  toolTip,
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      my: 1,
    }}
  >
    <Typography
      variant="body2"
      fontWeight={bold ? "medium" : "normal"}
      color={bold ? "text.primary" : "text.secondary"}
    >
      {title}
    </Typography>
    <Tooltip title={toolTip}>
      <TextField
        value={value.toFixed(2)}
        onChange={(e) => onValueChange(parseFloat(e.target.value) || 0)}
        variant="outlined"
        size="small"
        type="number"
        inputProps={{ step: "0.01" }}
        sx={{
          width: "120px",
          backgroundColor: changed ? "#fdeded" : "inherit",
        }}
      />
    </Tooltip>
  </Box>
);

export const StatusChip = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "warning";
      case "approved":
        return "success";
      case "rejected":
        return "error";
      case "Calculation Error":
        return "warning";
      case "Calculation Passed":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Chip
      label={status || "Unknown"}
      color={getStatusColor(status)}
      size="small"
    />
  );
};

const searchClient = liteClient(
  "LM6U4D5EAY",
  "17c93ab5b4cc3b5ac604fd089467ccf1"
);

export const InfoCard = ({ title, taxId, icon, onTaxIdChange, addCompany }) => {
  const [options, setOptions] = useState([]);

  const handleSearch = async (query) => {
    try {
      const { results } = await searchClient.search([
        {
          indexName: "companies",
          query,
          params: {
            hitsPerPage: 5,
          },
        },
      ]);

      const hits = results[0].hits;
      setOptions(
        hits.map((hit) => ({
          label: hit.name,
          taxId: hit.taxId,
          ...hit,
        }))
      );
    } catch (error) {
      console.error("Error searching companies:", error);
    }
  };

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "#f0f0f2",
        p: 3,
        borderRadius: 2,
        height: "100%",
        position: "relative",
      }}
    >
      <Button
        onClick={addCompany}
        sx={{
          position: "absolute",
          top: 10,
          right: 0,
        }}
      >
        <Add />
      </Button>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        {icon}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: 2,
            width: "100%",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: "medium" }}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Tax ID: {taxId}
          </Typography>
        </Box>
      </Box>
      <Autocomplete
        freeSolo
        options={options}
        onInputChange={(event, newInputValue) => {
          handleSearch(newInputValue);
        }}
        onChange={(event, newValue) => {
          if (newValue && newValue.taxid) {
            onTaxIdChange({ taxId: newValue.taxid, name: newValue.label });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search Company"
            variant="outlined"
            size="small"
            fullWidth
          />
        )}
      />
    </Card>
  );
};

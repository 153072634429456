import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
  where,
} from "firebase/firestore";

import { createCurrency } from "../../utils";
import { db } from "../../firebase";
import { fetchCustomerProducts } from "../../scripts";
import { useNavigate } from "react-router-dom";

const styles = {
  container: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  form: {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
  },
  table: {
    minWidth: 650,
  },
  filters: {
    display: "flex",
    gap: "10px",
    marginBottom: "20px",
  },
  productList: {
    maxHeight: "calc(100vh - 200px)",
    overflow: "auto",
  },
};

export default function Recipes() {
  const [recipes, setRecipes] = useState([]);
  const [recipeSearchTerm, setRecipeSearchTerm] = useState("");
  const [sortCategory, setSortCategory] = useState("");
  const [selectedRecipes, setSelectedRecipes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buyerProducts, setBuyerProducts] = useState([]);
  const [productSearchTerm, setProductSearchTerm] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchRecipes();
    fetchCustomerProducts({
      setLoading,
      customerId: "0680629872",
      setBuyerProducts,
    });
  }, []);

  const fetchRecipes = async () => {
    try {
      const querySnapshot = await getDocs(
        collection(db, "recipes"),
        where("customerId", "==", "0680629872")
      );
      const recipeList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setRecipes(recipeList);
    } catch (error) {
      console.error("Error fetching recipes:", error);
    }
  };

  const handleSort = (category) => {
    setSortCategory(category);
    const sortedRecipes = [...recipes].sort((a, b) =>
      a.category.localeCompare(b.category)
    );
    setRecipes(sortedRecipes);
  };

  const handleRecipeSearch = (event) => {
    setRecipeSearchTerm(event.target.value);
  };

  const handleProductSearch = (event) => {
    setProductSearchTerm(event.target.value);
  };

  const handleSelectRecipe = (id) => {
    setSelectedRecipes((prev) =>
      prev.includes(id)
        ? prev.filter((recipeId) => recipeId !== id)
        : [...prev, id]
    );
  };

  const handleAddIngredient = async (product) => {
    if (selectedRecipes.length > 0) {
      try {
        for (const recipeId of selectedRecipes) {
          const recipeRef = doc(db, "recipes", recipeId);
          const recipe = recipes.find((r) => r.id === recipeId);

          if (recipe) {
            const updatedIngredients = [
              ...recipe.ingredients,
              { id: product.id, name: product?.productName, quantity: 0 },
            ];
            await updateDoc(recipeRef, { ingredients: updatedIngredients });
          }
        }

        // Refresh recipes after updating
        await fetchRecipes();
      } catch (error) {
        console.error("Error adding ingredient to recipes:", error);
      }
    }
  };

  const handleRemoveIngredient = async (recipeId, ingredientId) => {
    try {
      const recipeRef = doc(db, "recipes", recipeId);
      const recipe = recipes.find((r) => r.id === recipeId);

      if (recipe) {
        const updatedIngredients = recipe.ingredients.filter(
          (ing) => ing.id !== ingredientId
        );
        await updateDoc(recipeRef, { ingredients: updatedIngredients });
      }

      // Refresh recipes after updating
      await fetchRecipes();
    } catch (error) {
      console.error("Error removing ingredient from recipe:", error);
    }
  };

  const filteredRecipes = recipes.filter(
    (recipe) =>
      recipe.name.toLowerCase().includes(recipeSearchTerm.toLowerCase()) ||
      recipe.category.toLowerCase().includes(recipeSearchTerm.toLowerCase())
  );

  const filteredProducts = buyerProducts.filter((product) =>
    product?.productName
      ?.toLowerCase()
      .includes(productSearchTerm?.toLowerCase())
  );

  return (
    <Container sx={styles.container}>
      <Typography variant="h4" gutterBottom>
        Recipes and Products
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          <TextField
            label="Search Products"
            variant="outlined"
            value={productSearchTerm}
            onChange={handleProductSearch}
            fullWidth
            margin="normal"
          />
          <Paper>
            <List sx={styles.productList}>
              {filteredProducts.map((product) => (
                <ListItem key={product.id}>
                  <ListItemText
                    primary={product.productName}
                    secondary={createCurrency(product.price).format()}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="add"
                      onClick={() => handleAddIngredient(product)}
                      disabled={selectedRecipes.length === 0}
                    >
                      <AddIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom>
            Recipes
          </Typography>
          <div style={styles.filters}>
            <TextField
              label="Search Recipes"
              variant="outlined"
              value={recipeSearchTerm}
              onChange={handleRecipeSearch}
              fullWidth
            />
            <FormControl style={{ minWidth: 120 }}>
              <InputLabel>Sort by Category</InputLabel>
              <Select
                value={sortCategory}
                onChange={(e) => handleSort(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </FormControl>
          </div>

          <TableContainer component={Paper}>
            <Table sx={styles.table} aria-label="recipes table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedRecipes.length > 0 &&
                        selectedRecipes.length < recipes.length
                      }
                      checked={selectedRecipes.length === recipes.length}
                      onChange={() =>
                        setSelectedRecipes(
                          selectedRecipes.length === recipes.length
                            ? []
                            : recipes.map((r) => r.id)
                        )
                      }
                    />
                  </TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Company ID</TableCell>
                  <TableCell align="right">Ingredients</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRecipes.map((recipe) => (
                  <React.Fragment key={recipe.id}>
                    <TableRow
                      selected={selectedRecipes.includes(recipe.id)}
                      sx={{
                        backgroundColor: "#e8f2ff",
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedRecipes.includes(recipe.id)}
                          onChange={() => handleSelectRecipe(recipe.id)}
                        />
                      </TableCell>
                      <TableCell>{recipe.category}</TableCell>
                      <TableCell>{recipe.name}</TableCell>
                      <TableCell align="right">
                        {createCurrency(recipe.price).format()}
                      </TableCell>
                      <TableCell align="right">{recipe.companyId}</TableCell>
                      <TableCell align="right">
                        {recipe.ingredients.length} ingredients
                      </TableCell>
                      <TableCell align="right">
                        {/* Add any recipe-level actions here */}
                      </TableCell>
                    </TableRow>
                    {recipe.ingredients.map((ingredient) => (
                      <TableRow key={`${recipe.id}-${ingredient.id}`}>
                        <TableCell align="right">
                          <IconButton
                            edge="end"
                            aria-label="remove"
                            onClick={() =>
                              handleRemoveIngredient(recipe.id, ingredient.id)
                            }
                          >
                            <RemoveIcon />
                          </IconButton>
                        </TableCell>
                        <TableCell colSpan={3}>{ingredient.name}</TableCell>
                        <TableCell colSpan={3} />
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

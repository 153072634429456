import { Add, Close, Percent, Remove } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { enqueueSnackbar } from "notistack";

const MiniCalculator = () => {
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [result, setResult] = useState("");
  const [operation, setOperation] = useState("+");

  const calculate = (op) => {
    const a = parseFloat(num1);
    const b = parseFloat(num2);
    let calculatedResult;

    switch (op) {
      case "+":
        calculatedResult = a + b;
        break;
      case "-":
        calculatedResult = a - b;
        break;
      case "*":
        calculatedResult = a * b;
        break;
      case "%":
        calculatedResult = a / b;
        break;
      default:
        calculatedResult = "Error: Invalid operation";
    }

    setResult(calculatedResult.toString());
    setOperation(op);
  };

  const copyResult = () => {
    navigator.clipboard.writeText(result);
    enqueueSnackbar("Result copied to clipboard", { variant: "success" });
  };

  useEffect(() => {
    if (num1 && num2) {
      calculate(operation);
    }
  }, [num1, num2, operation]);

  const OperationButton = ({ op, icon, title }) => (
    <IconButton
      onClick={() => calculate(op)}
      color={operation === op ? "warning" : "default"}
      sx={{
        bgcolor: operation === op ? "#1b1b1b" : "transparent",
      }}
    >
      {icon}
    </IconButton>
  );

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 2,
        maxWidth: "500px",
        width: "100%",
      }}
    >
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              label="First Number"
              type="number"
              value={num1}
              onChange={(e) => setNum1(e.target.value)}
              fullWidth
              variant="outlined"
            />
            <TextField
              label="Second Number"
              type="number"
              value={num2}
              onChange={(e) => setNum2(e.target.value)}
              fullWidth
              variant="outlined"
            />

            <TextField
              label="Result"
              value={result}
              InputProps={{
                readOnly: true,
              }}
              fullWidth
              variant="filled"
              onClick={copyResult}
            />
          </Grid>
          <Grid
            item
            xs={4}
            container
            direction="column"
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <OperationButton op="+" icon={<Add />} title="Add" />
            <OperationButton op="-" icon={<Remove />} title="Subtract" />
            <OperationButton op="*" icon={<Close />} title="Multiply" />
            <OperationButton op="%" icon={<Percent />} title="Modulo" />
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 2,
            bgcolor: "rgba(255, 255, 255, 0.05)",
            //   p: 2,
            borderRadius: 2,
          }}
        ></Box>
      </CardContent>
    </Card>
  );
};

export default MiniCalculator;

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Cancel, CheckCircle, Edit, Search } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InfoCard } from "../../components";
import { fetchCompanies } from "../../scripts";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "imgUrl", label: "Logo" },
  { id: "name", label: "Company Name" },
  { id: "taxid", label: "Tax ID" },
  { id: "city", label: "City" },
  { id: "phone", label: "Phone" },
  { id: "mail", label: "Email" },
  { id: "verified", label: "Verified" },
];

export default function Companies() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyList, status, error } = useSelector(
    (state) => state.companies
  );
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!companyList?.length) {
      fetchCompanies({ dispatch });
    }
  }, []);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCompanies = companyList?.filter((company) =>
    Object.values(company).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  if (status === "loading") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === "failed") {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!companyList || companyList.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">No companies found.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Typography variant="h4" gutterBottom component="div">
        Companies
      </Typography>
      <Grid container mb={2}>
        <Grid item xs={4}>
          <InfoCard title="Total Companies" value={companyList.length} />
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search companies..."
          value={searchTerm}
          fullWidth
          onChange={handleSearch}
          InputProps={{
            startAdornment: <Search />,
          }}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredCompanies, getComparator(order, orderBy)).map(
              (company) => (
                <TableRow
                  hover
                  key={company.id}
                  onClick={() => navigate(`/company/${company.id}`)}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>
                    {company?.imgUrl && (
                      <img
                        src={company.imgUrl}
                        alt="company logo"
                        width="40"
                        height="40"
                      />
                    )}
                  </TableCell>
                  <TableCell>{company.name}</TableCell>
                  <TableCell>{company.taxid}</TableCell>
                  <TableCell>{company.city}</TableCell>
                  <TableCell>{company.phone}</TableCell>
                  <TableCell>{company.mail}</TableCell>
                  <TableCell>
                    {company.verified ? (
                      <CheckCircle sx={{ color: "green" }} />
                    ) : (
                      <Cancel sx={{ color: "red" }} />
                    )}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

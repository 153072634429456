import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  Close,
  CloudUpload,
  NavigateBefore,
  NavigateNext,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { fetchCompanies } from "../../scripts";

const sanitizeForFirestore = (str) => {
  return str?.replace(/\//g, "-").replace(/[^a-zA-Z0-9-_]/g, "_");
};

export default function EditCompany() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { status, error } = useSelector((state) => state.companies);

  const [editingCompany, setEditingCompany] = useState(null);
  const [invoiceUrls, setInvoiceUrls] = useState([]);
  const [currentInvoiceIndex, setCurrentInvoiceIndex] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);

  useEffect(() => {
    fetchCompanyDetails();
  }, [companyId]);

  async function fetchCompanyDetails() {
    try {
      const companyDoc = doc(db, "companyDetails", companyId);
      const companyDocSnap = await getDoc(companyDoc);
      if (companyDocSnap.exists()) {
        const company = companyDocSnap.data();
        setEditingCompany({ ...company, id: companyDocSnap.id });
        fetchCompanyInvoice(company);
      }
    } catch (error) {
      console.error("Error fetching company details:", error);
    }
  }

  async function fetchCompanyInvoice(company) {
    try {
      const q = query(
        collection(db, "cleanedInvoiceData"),
        where("fields.VendorTaxId.value", "==", company.taxid),
        orderBy("createdAt.seconds", "desc"),
        limit(3)
      );

      const querySnapshot = await getDocs(q);
      const filePaths = querySnapshot.docs.map((doc) => doc.data().filePath);

      const urls = await Promise.all(
        filePaths.map(async (filePath) => {
          const storageRef = ref(storage, filePath);
          return getDownloadURL(storageRef);
        })
      );

      setInvoiceUrls(urls);
    } catch (error) {
      console.error("Error fetching company invoices:", error);
    }
  }

  const handleSaveEdit = async () => {
    try {
      let imgUrl = editingCompany.imgUrl;

      if (logoFile) {
        imgUrl = await handleLogoUpload();
        if (!imgUrl) {
          console.error("Logo upload failed");
          return;
        }
      }

      const companyRef = doc(db, "companyDetails", editingCompany.id);
      await updateDoc(companyRef, {
        ...editingCompany,
        imgUrl: imgUrl || editingCompany.imgUrl || "",
      });

      fetchCompanies({ dispatch });
      navigate(-1);
    } catch (error) {
      console.error("Error saving edit:", error);
    }
  };

  const handleLogoUpload = async () => {
    if (!logoFile) return null;

    const logoName = sanitizeForFirestore(editingCompany?.name);
    if (!logoName) return null;

    const logoPath = `companyLogos/${logoName}`;
    const logoRef = ref(storage, logoPath);

    try {
      const snapshot = await uploadBytes(logoRef, logoFile);
      return getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditingCompany((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setEditingCompany((prev) => ({ ...prev, [name]: checked }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setLogoPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  if (status === "loading") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === "failed") {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!editingCompany) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">No company found.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", height: "100vh" }}>
      <Box
        sx={{
          width: "400px",
          backgroundColor: "white",
          p: 3,
          overflowY: "auto",
          borderRight: "1px solid #ccc",
        }}
      >
        <IconButton
          sx={{ position: "absolute", left: 10, top: 10 }}
          onClick={() => navigate(-1)}
        >
          <Close />
        </IconButton>
        <Typography variant="h6" gutterBottom mt={4}>
          Edit Company
        </Typography>
        <CompanyForm
          editingCompany={editingCompany}
          handleInputChange={handleInputChange}
          handleCheckboxChange={handleCheckboxChange}
          handleFileChange={handleFileChange}
          logoPreview={logoPreview}
        />
        <Box sx={{ mt: 2 }}>
          <Button onClick={() => navigate(-1)}>Cancel</Button>
          <Button onClick={handleSaveEdit}>Save</Button>
        </Box>
      </Box>
      <InvoiceViewer
        invoiceUrls={invoiceUrls}
        currentInvoiceIndex={currentInvoiceIndex}
        setCurrentInvoiceIndex={setCurrentInvoiceIndex}
        zoom={zoom}
        setZoom={setZoom}
      />
    </Box>
  );
}

function CompanyForm({
  editingCompany,
  handleInputChange,
  handleCheckboxChange,
  handleFileChange,
  logoPreview,
}) {
  return (
    <>
      <Box sx={{ my: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Company Logo
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {editingCompany?.imgUrl && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "50%",
                opacity: logoPreview ? 0.3 : 1,
              }}
            >
              <img
                src={editingCompany.imgUrl}
                alt="company logo"
                width="80%"
                height="80%"
                style={{ marginBottom: "10px" }}
              />
            </Box>
          )}
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="logo-upload"
            type="file"
            onChange={handleFileChange}
          />
          {logoPreview ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                flexGrow: 1,
              }}
            >
              <img
                src={logoPreview}
                alt="logo preview"
                style={{
                  maxWidth: "80%",
                  maxHeight: "80%",
                  objectFit: "contain",
                }}
              />
            </Box>
          ) : (
            <label htmlFor="logo-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUpload />}
              >
                Choose Logo
              </Button>
            </label>
          )}
        </Box>
      </Box>
      {[
        { name: "name", label: "Company Name" },
        { name: "taxid", label: "Tax ID" },
        { name: "taxoffice", label: "Tax Office" },
        { name: "city", label: "City" },
        { name: "country", label: "Country" },
        { name: "phone", label: "Phone" },
        { name: "mail", label: "Email", type: "email" },
      ].map((field) => (
        <TextField
          key={field.name}
          margin="dense"
          name={field.name}
          disabled={field.name === "taxid"}
          label={field.label}
          type={field.type || "text"}
          fullWidth
          variant="standard"
          value={editingCompany[field.name] || ""}
          onChange={handleInputChange}
        />
      ))}
      <RadioGroup
        aria-label="company-type"
        name="type"
        value={editingCompany?.type || ""}
        onChange={handleInputChange}
      >
        <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
        <FormControlLabel
          value="customer"
          control={<Radio />}
          label="Customer"
        />
      </RadioGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={editingCompany?.verified || false}
            onChange={handleCheckboxChange}
            name="verified"
          />
        }
        label="Verified"
      />
    </>
  );
}

function InvoiceViewer({
  invoiceUrls,
  currentInvoiceIndex,
  setCurrentInvoiceIndex,
  zoom,
  setZoom,
}) {
  const handleNextInvoice = () => {
    setCurrentInvoiceIndex((prevIndex) => (prevIndex + 1) % invoiceUrls.length);
  };

  const handlePreviousInvoice = () => {
    setCurrentInvoiceIndex(
      (prevIndex) => (prevIndex - 1 + invoiceUrls.length) % invoiceUrls.length
    );
  };

  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5));
  };

  return (
    <Box sx={{ flex: 1, overflow: "hidden", position: "relative" }}>
      {invoiceUrls.length > 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: `url(${invoiceUrls[currentInvoiceIndex]})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            transform: `scale(${zoom})`,
            transition: "transform 0.2s ease-in-out",
          }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Typography>No invoices available</Typography>
        </Box>
      )}

      {invoiceUrls.length > 1 && (
        <>
          <IconButton
            onClick={handlePreviousInvoice}
            sx={{
              position: "absolute",
              left: 20,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <NavigateBefore />
          </IconButton>
          <IconButton
            onClick={handleNextInvoice}
            sx={{
              position: "absolute",
              right: 20,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            <NavigateNext />
          </IconButton>
        </>
      )}

      <Box sx={{ position: "absolute", right: 20, bottom: 20 }}>
        <IconButton onClick={handleZoomIn}>
          <ZoomIn />
        </IconButton>
        <IconButton onClick={handleZoomOut}>
          <ZoomOut />
        </IconButton>
      </Box>
    </Box>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoices: null,
  pendingImages: [],
  calculationErrorCount: 0,
  approvedInvoices: null,
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    setInvoices: (state, action) => {
      state.invoices = action.payload;
    },
    setPendingImages: (state, action) => {
      state.pendingImages = action.payload;
    },
    setCalculationErrorCount: (state, action) => {
      state.calculationErrorCount = action.payload;
    },
    setApprovedInvoices: (state, action) => {
      state.approvedInvoices = action.payload;
    },
  },
});

export const {
  setInvoices,
  setPendingImages,
  setCalculationErrorCount,
  setApprovedInvoices,
} = invoicesSlice.actions;

export default invoicesSlice.reducer;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import {
  ContentCopy,
  Delete,
  ExpandMore,
  SyncRounded,
} from "@mui/icons-material";
import { DeleteProductDialog, InfoCard, MergeProduct } from "../components";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import { db } from "../firebase";
import { enqueueSnackbar } from "notistack";
import { liteClient } from "algoliasearch/lite";
import { styled } from "@mui/material/styles";
import { syncProducts } from "../scripts";
import uniqolor from "uniqolor";

const searchClient = liteClient(
  "LM6U4D5EAY",
  "17c93ab5b4cc3b5ac604fd089467ccf1"
);

const DEBOUNCE_DELAY = 300;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  display: "flex",
  width: "100%",
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme, width }) => ({
  width: width,
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  borderBottom: `1px solid ${theme.palette.divider}`,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

const columnWidths = {
  checkbox: "14%",
  image: "10%",
  genericName: "26%",
  productName: "30%",
  category: "20%",
};

const SearchBar = ({ value, onChange }) => (
  <TextField
    label="Search"
    variant="outlined"
    value={value}
    onChange={onChange}
    fullWidth
  />
);

const AdvancedFilters = ({ filters, onChange }) => (
  <Accordion sx={{ mt: 2 }}>
    <AccordionSummary expandIcon={<ExpandMore />}>
      <Typography>Advanced Filters</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <FormGroup>
        {Object.entries(filters).map(([key, value]) => (
          <FormControlLabel
            key={key}
            control={
              <Checkbox checked={value} onChange={onChange} name={key} />
            }
            label={`Missing ${key.replace("missing", "")}`}
          />
        ))}
      </FormGroup>
    </AccordionDetails>
  </Accordion>
);

const TableHeaderRow = () => (
  <StyledTableRow>
    <StyledTableCell padding="checkbox" width={columnWidths.checkbox}>
      Actions
    </StyledTableCell>
    <StyledTableCell width={columnWidths.image}>Image</StyledTableCell>
    {["genericName", "productName", "category"].map((column) => (
      <StyledTableCell key={column} width={columnWidths[column]}>
        <TableSortLabel>
          {column.charAt(0).toUpperCase() + column.slice(1)}
        </TableSortLabel>
      </StyledTableCell>
    ))}
  </StyledTableRow>
);

const ProductRow = React.memo(
  ({
    product,
    style,
    isSelected,
    onSelect,
    onCopy,
    onCellClick,
    onImageClick,
    setOpenDeleteDialog,
  }) => (
    <StyledTableRow style={{ ...style, display: "flex" }}>
      <StyledTableCell padding="checkbox" width={columnWidths.checkbox}>
        <Checkbox checked={isSelected} onChange={() => onSelect(product.id)} />
        <ContentCopy onClick={() => onCopy(product)} />
        <Delete onClick={() => setOpenDeleteDialog(product)} />
      </StyledTableCell>
      <StyledTableCell width={columnWidths.image}>
        <Avatar
          src={product.imgUrl || "/placeholder-image.jpg"}
          alt={product.productName}
          variant="square"
          sx={{ width: 50, height: 50 }}
          onClick={() => onImageClick(product.imgUrl)}
        />
      </StyledTableCell>
      {["genericName", "productName"].map((column) => (
        <StyledTableCell
          key={column}
          onClick={() => onCellClick(product[column])}
          width={columnWidths[column]}
          sx={{
            backgroundColor: `${uniqolor(product[column] || "N/A").color}40`,
            cursor: "pointer",
          }}
        >
          {product[column] || "N/A"}
          {column === "productName" && (
            <>
              <br />
              {product?.name}
            </>
          )}
        </StyledTableCell>
      ))}
      <Box sx={{ width: "100%", justifyContent: "space-around" }}>
        <Box
          sx={{
            backgroundColor: `${uniqolor(product?.category || "N/A").color}20`,
            height: "50%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>{product?.category}</Typography>
        </Box>
        <Box
          sx={{
            backgroundColor: `${
              uniqolor(product?.subCategory || "N/A").color
            }20`,
            height: "50%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography>{product?.subCategory}</Typography>
        </Box>
      </Box>
    </StyledTableRow>
  )
);

const GroupAccordion = React.memo(
  ({
    group,
    onProductSelect,
    selectedProducts,
    onCopy,
    onCellClick,
    onImageClick,
    isExpanded,
    onToggle,
    style,
    setOpenDeleteDialog,
  }) => {
    const handleChange = (event, expanded) => {
      onToggle(group.productName, expanded);
    };

    return (
      <Accordion expanded={isExpanded} onChange={handleChange} style={style}>
        <AccordionSummary
          sx={{
            alignItems: "center",
            display: "flex",
          }}
          expandIcon={<ExpandMore />}
        >
          <Avatar
            src={group.products[0].imgUrl || "/placeholder-image.jpg"}
            alt={group.products[0].productName}
            variant="square"
            sx={{ width: 36, height: 36, marginRight: 1 }}
            onClick={() => onImageClick(group.products[0].imgUrl)}
          />
          <Typography>{group.productName}</Typography>
          <Chip
            sx={{
              marginLeft: 2,
            }}
            color={
              group.products.length === 1
                ? "default"
                : group.products.length === 2
                ? "warning"
                : "error"
            }
            label={group.products.length}
          />
        </AccordionSummary>
        <AccordionDetails
          sx={{
            margin: 0,
            padding: 0,
          }}
        >
          {isExpanded && (
            <List
              height={Math.min(300, group.products.length * 60)}
              itemCount={group.products.length}
              itemSize={() => 60}
              width={"100%"}
              style={{ overflowX: "hidden" }}
            >
              {({ index, style }) => (
                <ProductRow
                  product={group.products[index]}
                  style={style}
                  isSelected={selectedProducts.includes(
                    group.products[index].id
                  )}
                  onSelect={onProductSelect}
                  onCopy={onCopy}
                  onCellClick={onCellClick}
                  onImageClick={onImageClick}
                  setOpenDeleteDialog={setOpenDeleteDialog}
                />
              )}
            </List>
          )}
        </AccordionDetails>
      </Accordion>
    );
  }
);

const VirtualizedGroupList = React.memo(
  ({ groups, expandedGroups, onToggleGroup, ...props }) => {
    const listRef = useRef();

    const getItemSize = (index) => {
      const group = groups[index];
      return expandedGroups[group.productName]
        ? Math.min(500, group.products.length * 90 + 60) // 60 for accordion header + up to 300 for content
        : 60; // Height of collapsed accordion
    };

    useEffect(() => {
      if (listRef.current) {
        listRef.current.resetAfterIndex(0);
      }
    }, [expandedGroups]);

    return (
      <AutoSizer>
        {({ height, width }) => (
          <List
            ref={listRef}
            height={height}
            itemCount={groups.length}
            itemSize={getItemSize}
            width={width}
            style={{ overflowX: "hidden" }}
          >
            {({ index, style }) => (
              <GroupAccordion
                group={groups[index]}
                style={style}
                isExpanded={expandedGroups[groups[index].productName]}
                onToggle={onToggleGroup}
                {...props}
              />
            )}
          </List>
        )}
      </AutoSizer>
    );
  }
);

export default function Products() {
  const [products, setProducts] = useState([]);
  const [copyProduct, setCopyProduct] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState(" ");
  const [advancedFilters, setAdvancedFilters] = useState({
    missingCategory: false,
    missingSubcategory: false,
    missingGenericName: false,
    missingImgUrl: false,
  });
  const [expandedGroups, setExpandedGroups] = useState({});
  const [openDeleteDialog, setOpenDeleteDialog] = useState();

  const fetchProducts = useCallback(async () => {
    try {
      setExpandedGroups({});
      setProducts([]);
      let fetchedProducts = [];

      if (searchTerm?.length >= 1) {
        const { results } = await searchClient.search([
          {
            indexName: "products",
            query: searchTerm,
            params: {
              hitsPerPage: 1000,
              numericFilters: ["inUse=1"], // Changed from filters to numericFilters
            },
          },
        ]);
        fetchedProducts = results[0].hits.map((hit) => ({
          id: hit.objectID,
          name: hit.productName,
          ...hit,
        }));
        console.log({ fetchedProducts });
      } else {
        const q = query(
          collection(db, "products"),
          where("inUse", "==", true),
          orderBy("productName", "asc")
        );
        const querySnapshot = await getDocs(q);
        fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }

      setProducts(fetchedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
      enqueueSnackbar("Failed to fetch products", { variant: "error" });
    }
  }, [searchTerm]);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchProducts();
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timer);
  }, [fetchProducts, searchTerm]);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleAdvancedFilterChange = (event) => {
    setAdvancedFilters((prev) => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleProductSelect = useCallback((productId) => {
    setSelectedProducts((prev) =>
      prev.includes(productId)
        ? prev.filter((id) => id !== productId)
        : [...prev, productId]
    );
  }, []);

  const handleCellClick = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  const copyImageUrl = (url) => {
    navigator.clipboard.writeText(url);
    enqueueSnackbar("Image URL copied to clipboard", { variant: "success" });
  };

  const handleToggleGroup = useCallback((groupName, isExpanded) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupName]: isExpanded,
    }));
  }, []);

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      if (advancedFilters.missingCategory && product.category) return false;
      if (advancedFilters.missingSubcategory && product.subCategory)
        return false;
      if (advancedFilters.missingGenericName && product.genericName)
        return false;
      if (advancedFilters.missingImgUrl && product.imgUrl) return false;
      return true;
    });
  }, [products, advancedFilters]);

  const groupedProducts = useMemo(() => {
    const groups = {};
    filteredProducts.forEach((product) => {
      if (!groups[product.productName]) {
        groups[product.productName] = {
          productName: product.productName,
          products: [],
        };
      }
      groups[product.productName].products.push(product);
    });
    return Object.values(groups);
  }, [filteredProducts]);

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Box sx={{ display: "flex", flexDirection: "row" }}>
        <Box sx={{ mb: 2, width: "70%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }} mb={2}>
            <Typography variant="h4" gutterBottom component="div">
              Products
            </Typography>
            <Button
              onClick={syncProducts}
              variant="outlined"
              size="small"
              startIcon={<SyncRounded />}
              disabled
            >
              Update Product List
            </Button>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InfoCard
                title="Total Products"
                value={filteredProducts.length}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InfoCard
                title="Number of Groups"
                value={groupedProducts.length}
              />
            </Grid>
            <Grid item xs={12}>
              <SearchBar value={searchTerm} onChange={handleSearchTermChange} />
            </Grid>
          </Grid>

          <AdvancedFilters
            filters={advancedFilters}
            onChange={handleAdvancedFilterChange}
          />

          <TableContainer
            component={Paper}
            sx={{ height: "calc(100vh - 250px)" }}
          >
            <Table style={{ tableLayout: "fixed" }}>
              <TableHead>
                <TableHeaderRow />
              </TableHead>
            </Table>
            <VirtualizedGroupList
              groups={groupedProducts}
              expandedGroups={expandedGroups}
              onToggleGroup={handleToggleGroup}
              onProductSelect={handleProductSelect}
              selectedProducts={selectedProducts}
              onCopy={setCopyProduct}
              onCellClick={handleCellClick}
              onImageClick={copyImageUrl}
              setOpenDeleteDialog={setOpenDeleteDialog}
            />
          </TableContainer>
        </Box>

        <MergeProduct
          products={selectedProducts}
          copyProduct={copyProduct}
          fetchProducts={() => {
            fetchProducts();
            setSelectedProducts([]);
          }}
        />
        <DeleteProductDialog
          open={Boolean(openDeleteDialog)}
          onClose={() => setOpenDeleteDialog()}
          product={openDeleteDialog}
        />
      </Box>
    </Box>
  );
}

import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";

import { ProductInvoices } from "../../components";
import { db } from "../../firebase";

const PriceCard = ({ lowPrice, highPrice, priceDifference, isCity }) => (
  <Card
    sx={{
      bgcolor: isCity ? "background.default" : "primary.light",
      height: "100%",
    }}
  >
    <CardContent>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography
            variant="body2"
            color={isCity ? "text.secondary" : "primary.contrastText"}
          >
            Low
          </Typography>
          <Typography
            variant="h5"
            color={isCity ? "text.primary" : "primary.contrastText"}
          >
            {lowPrice.toFixed(2)} TL
          </Typography>
          <Typography
            variant="body2"
            color={isCity ? "text.secondary" : "primary.contrastText"}
            sx={{ mt: 1 }}
          >
            High
          </Typography>
          <Typography
            variant="h5"
            color={isCity ? "text.primary" : "primary.contrastText"}
          >
            {highPrice.toFixed(2)} TL
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="body2"
            color={isCity ? "text.secondary" : "primary.contrastText"}
          >
            Price Difference
          </Typography>
          <Typography
            variant="h4"
            color={isCity ? "primary.main" : "primary.contrastText"}
          >
            {(priceDifference * 100).toFixed(2)}%
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

const CountCard = ({ vendorCount, customerCount, isCity }) => (
  <Card
    sx={{
      bgcolor: isCity ? "background.default" : "secondary.light",
      height: "100%",
    }}
  >
    <CardContent>
      <Typography
        variant="body2"
        color={isCity ? "text.secondary" : "secondary.contrastText"}
      >
        Vendor Count: {vendorCount}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(vendorCount / (vendorCount + customerCount)) * 100}
        sx={{ my: 1 }}
      />
      <Typography
        variant="body2"
        color={isCity ? "text.secondary" : "secondary.contrastText"}
      >
        Customer Count: {customerCount}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={(customerCount / (vendorCount + customerCount)) * 100}
        sx={{ my: 1 }}
        color="secondary"
      />
    </CardContent>
  </Card>
);

const QuantityCard = ({ totalQuantity, totalQuantityWeight, unit, isCity }) => (
  <Card
    sx={{
      bgcolor: isCity ? "background.default" : "success.light",
      height: "100%",
    }}
  >
    <CardContent>
      <Typography
        variant="body2"
        color={isCity ? "text.secondary" : "success.contrastText"}
      >
        Total Quantity
      </Typography>
      <Typography
        variant="h4"
        color={isCity ? "text.primary" : "success.contrastText"}
      >
        {totalQuantity.toFixed(2)} {unit}
      </Typography>
      <Typography
        variant="body2"
        color={isCity ? "text.secondary" : "success.contrastText"}
      >
        Quantity Weight
      </Typography>
      <Typography
        variant="h4"
        color={isCity ? "primary.main" : "primary.contrastText"}
      >
        {totalQuantityWeight.toFixed(2)}%
      </Typography>
    </CardContent>
  </Card>
);

const DataDisplay = ({ country, data, isCity = false }) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        mb: 4,
        p: 2,
        borderRadius: 1,
      }}
    >
      <Button
        variant="h6"
        sx={{ mb: 2, color: isCity ? "text.secondary" : "primary.main" }}
        onClick={() => {
          if (isCity) {
            navigate(`${country?.id}/city/${data.city}`);
          }
        }}
      >
        {isCity ? data.city : `${data.country} / ${data.productName}`}
      </Button>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <PriceCard
            lowPrice={data.lowPrice}
            highPrice={data.highPrice}
            priceDifference={data.priceDifference}
            isCity={isCity}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <CountCard
            vendorCount={data.vendorCount}
            customerCount={data.customerCount}
            isCity={isCity}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <QuantityCard
            totalQuantity={data.totalQuantity}
            totalQuantityWeight={data.totalQuantityWeight}
            unit={data.unit}
            isCity={isCity}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const CountryProduct = ({ country }) => {
  const [cities, setCities] = useState([]);
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchCities();
  }, []);

  async function fetchCities() {
    setIsLoadingCities(true);
    setError(null);
    try {
      const cityQuery = collection(
        db,
        `procurementInsights/${country.productId}/country/${country.id}/city`
      );
      const cityQuerySnapshot = await getDocs(cityQuery);
      const fetchedCities = cityQuerySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCities(fetchedCities);
    } catch (error) {
      console.error("Error fetching cities:", error);
      setError("Failed to fetch cities. Please try again later.");
    } finally {
      setIsLoadingCities(false);
    }
  }

  return (
    <Box sx={{ mb: 6 }}>
      <DataDisplay data={country} />
      {isLoadingCities ? (
        <CircularProgress />
      ) : error ? (
        <Alert severity="error">{error}</Alert>
      ) : cities.length === 0 ? (
        <Alert severity="info">No cities found for this country.</Alert>
      ) : (
        cities.map((city) => (
          <DataDisplay country={country} key={city.id} data={city} isCity />
        ))
      )}
    </Box>
  );
};

export default function Products() {
  const { productId } = useParams();
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productName, setProductName] = useState();

  useEffect(() => {
    fetchCountries();
  }, [productId]);

  async function fetchCountries() {
    setIsLoading(true);
    setError(null);
    try {
      const query = collection(db, `procurementInsights/${productId}/country`);
      const querySnapshot = await getDocs(query);
      const fetchedProducts = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        productId,
        ...doc.data(),
      }));
      setProductName(fetchedProducts[0]?.productName);
      setCountries(fetchedProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
      setError("Failed to fetch products. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" sx={{ mb: 4 }}>
        Product Analysis
      </Typography>
      {countries.length === 0 ? (
        <Alert severity="info">No products found.</Alert>
      ) : (
        countries.map((country) => (
          <CountryProduct key={country.id} country={country} />
        ))
      )}
      <ProductInvoices productName={productName} />
    </Box>
  );
}

import {
  Box,
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Business, WorkHistory } from "@mui/icons-material";
import React, { useMemo } from "react";

import { InfoCard } from "../../components";
import { createCurrency } from "../../utils";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const StatusChip = ({ status }) => {
  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "warning";
      case "approved":
        return "success";
      case "rejected":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Chip
      label={status || "Unknown"}
      color={getStatusColor(status)}
      size="small"
    />
  );
};

export default function Invoices() {
  const { invoices, pendingImages, calculationErrorCount } = useSelector(
    (state) => state.invoices
  );
  const navigate = useNavigate();

  const handleRowClick = (event, invoiceId) => {
    if (event.button === 1 || event.ctrlKey || event.metaKey) {
      window.open(`/invoice/${invoiceId}`, "_blank", "noopener,noreferrer");
      event.preventDefault();
    } else {
      navigate(`/invoice/${invoiceId}`);
    }
  };

  const duplicateInvoices = useMemo(() => {
    const invoiceMap = new Map();
    const duplicates = new Set();

    invoices?.forEach((invoice) => {
      const key = `${invoice.vendorId}-${invoice.customerId}-${invoice.total}`;
      if (invoiceMap.has(key)) {
        duplicates.add(invoiceMap.get(key));
        duplicates.add(invoice.id);
      } else {
        invoiceMap.set(key, invoice.id);
      }
    });

    return duplicates;
  }, [invoices]);
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Invoices Pending Inspection
      </Typography>

      <Grid container spacing={3} sx={{ mb: 4 }}>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            title="Pending Invoices"
            value={invoices?.length}
            icon={<Business />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            title="Pending Images"
            value={pendingImages?.length}
            icon={<WorkHistory />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            title="Calculation Error Count"
            value={calculationErrorCount}
            icon={<WorkHistory />}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <InfoCard
            title="Duplicate Invoices"
            value={duplicateInvoices.size}
            icon={<WorkHistory />}
          />
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="invoices table">
          <TableHead>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices?.map((invoice) => {
              return (
                <TableRow
                  key={invoice.id}
                  onClick={(event) => handleRowClick(event, invoice.id)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      duplicateInvoices.has(invoice.id) && "#fdeded",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {invoice?.vendorName || "Unknown"}
                  </TableCell>
                  <TableCell>{invoice?.customerName || "Unknown"}</TableCell>
                  <TableCell>
                    {invoice.date
                      ? new Date(
                          invoice.date.seconds * 1000
                        ).toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    <StatusChip
                      status={createCurrency(invoice.total).format()}
                    />
                  </TableCell>
                  <TableCell>
                    <StatusChip status={invoice.status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { styled } from "@mui/material/styles";

const DropZone = styled(Box)(({ theme, isDragActive }) => ({
  border: `2px dashed ${
    isDragActive ? theme.palette.primary.main : theme.palette.grey[300]
  }`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  cursor: "pointer",
  transition: "border .3s ease-in-out",
  backgroundColor: isDragActive ? theme.palette.action.hover : "transparent",
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const PriceListPage = () => {
  const [file, setFile] = useState(null);
  const [products, setProducts] = useState([]);
  const [isDragActive, setIsDragActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const apiKey = "a3279784fdf44313ad8e6f04c02c1e71";
  const endpoint = "https://teklif-app-east.openai.azure.com/";
  const deploymentName = "gpt-4o";

  const handleFileUpload = useCallback((uploadedFile) => {
    setFile(uploadedFile);
    processFile(uploadedFile);
  }, []);

  const readTextFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.onerror = (e) => reject(e);
      reader.readAsText(file);
    });
  };

  const readBinaryFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const arrayBuffer = e.target.result;
        const base64String = btoa(
          new Uint8Array(arrayBuffer).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        resolve(`data:${file.type};base64,${base64String}`);
      };
      reader.onerror = (e) => reject(e);
      reader.readAsArrayBuffer(file);
    });
  };

  const processFile = async (file) => {
    setIsLoading(true);
    setError(null);
    setProducts([]);

    try {
      let fileContent;
      if (file.type.startsWith("text/") || file.type === "application/json") {
        fileContent = await readTextFile(file);
      } else {
        fileContent = await readBinaryFile(file);
      }

      const result = await axios.post(
        `${endpoint}openai/deployments/${deploymentName}/chat/completions?api-version=2023-05-15`,
        {
          messages: [
            {
              role: "system",
              content:
                "You are a helpful assistant that can process various file formats and extract product information. Always respond with valid JSON.",
            },
            {
              role: "user",
              content: `Please process this ${file.type} file and return the product information as a JSON array. Each product should have productName, category, subCategory, and price fields. Respond only with the JSON array, no other text. The file content is: ${fileContent}`,
            },
          ],
        },
        {
          headers: {
            "Content-Type": "application/json",
            "api-key": apiKey,
          },
        }
      );

      let jsonString = result.data.choices[0].message.content;

      // Remove any markdown formatting
      jsonString = jsonString.replace(/```json\s?|\s?```/g, "").trim();

      // Parse the JSON
      const processedData = JSON.parse(jsonString);
      console.log({ processedData });

      if (Array.isArray(processedData)) {
        setProducts(processedData);
        setSuccessMessage("File processed successfully!");
      } else {
        throw new Error("Unexpected response format");
      }
    } catch (error) {
      console.error("Error:", error);
      setError(
        "An error occurred while processing the file. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragActive(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragActive(false);

      const droppedFile = e.dataTransfer.files[0];
      handleFileUpload(droppedFile);
    },
    [handleFileUpload]
  );

  const handleRemoveFile = useCallback(() => {
    setFile(null);
    setProducts([]);
    setError(null);
  }, []);

  return (
    <Box sx={{ maxWidth: 800, margin: "auto", padding: 2 }}>
      <Typography variant="h4" gutterBottom>
        Price List Generator
      </Typography>

      <DropZone
        isDragActive={isDragActive}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        sx={{ mb: 3 }}
      >
        {!file ? (
          <>
            <CloudUploadIcon
              sx={{ fontSize: 48, color: "action.active", mb: 1 }}
            />
            <Typography variant="h6" gutterBottom>
              Drag and drop a file here, or click to select a file
            </Typography>
            <Button component="label" variant="contained">
              Select File
              <VisuallyHiddenInput
                type="file"
                onChange={(e) => handleFileUpload(e.target.files[0])}
                accept=".xlsx,.xls,.csv,.pdf"
              />
            </Button>
            <Typography variant="caption" display="block" sx={{ mt: 1 }}>
              Supported formats: Excel, CSV, PDF
            </Typography>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography>{file.name}</Typography>
            <Button onClick={handleRemoveFile} startIcon={<DeleteIcon />}>
              Remove
            </Button>
          </Box>
        )}
      </DropZone>

      {isLoading && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 2 }}>
          <CircularProgress />
        </Box>
      )}

      {products.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product Name</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Sub-Category</TableCell>
                <TableCell>Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>{product.productName}</TableCell>
                  <TableCell>{product.category}</TableCell>
                  <TableCell>{product.subCategory}</TableCell>
                  <TableCell>{product.price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert
          onClose={() => setError(null)}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PriceListPage;

import { collection, onSnapshot, query, where } from "firebase/firestore";
import { setCalculationErrorCount, setInvoices } from "../redux";

import { db } from "../firebase";

export function fetchInvoices({ dispatch }) {
  const invoicesQuery = query(
    collection(db, "cleanedInvoiceData"),
    where("status", "!=", "admin-approved")
  );

  const calculationErrorQuery = query(
    collection(db, "cleanedInvoiceData"),
    where("calculationError", "==", true)
  );

  const unsubscribeInvoices = onSnapshot(
    invoicesQuery,
    (querySnapshot) => {
      const fetchedInvoices = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(setInvoices(fetchedInvoices));
    },
    (error) => {
      console.error("Error listening to invoices:", error);
    }
  );

  const unsubscribeCalculationError = onSnapshot(
    calculationErrorQuery,
    (querySnapshot) => {
      const calculationErrorCount = querySnapshot.docs.length;
      console.log({ calculationErrorCount });
      dispatch(setCalculationErrorCount(calculationErrorCount));
    },
    (error) => {
      console.error("Error listening to calculation errors:", error);
    }
  );

  return () => {
    unsubscribeInvoices();
    unsubscribeCalculationError();
  };
}

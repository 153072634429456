import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Close, CloudUpload } from "@mui/icons-material";
import React, { useState } from "react";
import { db, storage } from "../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

const sanitizeForFirestore = (str) => {
  return str?.replace(/\//g, "-").replace(/[^a-zA-Z0-9-_]/g, "_");
};

export default function AddCompanyDialog({ open, onClose }) {
  const [newCompany, setNewCompany] = useState({
    name: "",
    taxid: "",
    taxoffice: "",
    city: "",
    country: "",
    phone: "",
    mail: "",
    type: "vendor",
    verified: false,
  });
  const [logoFile, setLogoFile] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [error, setError] = useState(null);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCompany((prev) => ({ ...prev, [name]: value }));
    if (name === "taxid") {
      setError(null); // Clear error when taxid is changed
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setNewCompany((prev) => ({ ...prev, [name]: checked }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => setLogoPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  const handleLogoUpload = async () => {
    if (!logoFile) return null;

    const logoName = sanitizeForFirestore(newCompany.name);
    if (!logoName) return null;

    const logoPath = `companyLogos/${logoName}`;
    const logoRef = ref(storage, logoPath);

    try {
      const snapshot = await uploadBytes(logoRef, logoFile);
      return getDownloadURL(snapshot.ref);
    } catch (error) {
      console.error("Error uploading file:", error);
      return null;
    }
  };

  const handleSave = async () => {
    try {
      if (!newCompany.taxid) {
        setError("Tax ID is required.");
        return;
      }

      // Check if a company with this taxid already exists
      const companyRef = doc(db, "companyDetails", newCompany.taxid);
      const companyDoc = await getDoc(companyRef);

      if (companyDoc.exists()) {
        setError("A company with this Tax ID already exists.");
        return;
      }

      let imgUrl = null;

      if (logoFile) {
        imgUrl = await handleLogoUpload();
        if (!imgUrl) {
          console.error("Logo upload failed");
          return;
        }
      }

      // Use setDoc instead of addDoc to specify the document ID
      await setDoc(companyRef, {
        ...newCompany,
        imgUrl: imgUrl || "",
      });

      onClose();
    } catch (error) {
      console.error("Error adding new company:", error);
      setError("An error occurred while adding the company. Please try again.");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add New Company
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box sx={{ my: 2 }}>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="logo-upload"
            type="file"
            onChange={handleFileChange}
          />
          {logoPreview ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
              }}
            >
              <img
                src={logoPreview}
                alt="logo preview"
                style={{
                  maxWidth: "80%",
                  maxHeight: "200px",
                  objectFit: "contain",
                }}
              />
            </Box>
          ) : (
            <label htmlFor="logo-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<CloudUpload />}
              >
                Choose Logo
              </Button>
            </label>
          )}
        </Box>
        {[
          { name: "name", label: "Company Name" },
          { name: "taxid", label: "Tax ID" },
          { name: "taxoffice", label: "Tax Office" },
          { name: "city", label: "City" },
          { name: "country", label: "Country" },
          { name: "phone", label: "Phone" },
          { name: "mail", label: "Email", type: "email" },
        ].map((field) => (
          <TextField
            key={field.name}
            margin="dense"
            name={field.name}
            label={field.label}
            type={field.type || "text"}
            fullWidth
            variant="standard"
            value={newCompany[field.name] || ""}
            onChange={handleInputChange}
            required={field.name === "taxid"}
          />
        ))}
        <RadioGroup
          aria-label="company-type"
          name="type"
          value={newCompany.type}
          onChange={handleInputChange}
        >
          <FormControlLabel value="vendor" control={<Radio />} label="Vendor" />
          <FormControlLabel
            value="customer"
            control={<Radio />}
            label="Customer"
          />
        </RadioGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={newCompany.verified}
              onChange={handleCheckboxChange}
              name="verified"
            />
          }
          label="Verified"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

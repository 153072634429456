import { Box, List, ListItem, ListItemText, TextField } from "@mui/material";
import React, { useState } from "react";

import { liteClient } from "algoliasearch/lite";

const searchClient = liteClient(
  "LM6U4D5EAY",
  "17c93ab5b4cc3b5ac604fd089467ccf1"
);

export default function SearchProducts({ onSelect }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    handleSearch(query);
  };

  const handleSearch = async (query) => {
    try {
      const { results } = await searchClient.search([
        {
          indexName: "products",
          query,
          params: {
            hitsPerPage: 5,
          },
        },
      ]);

      const hits = results[0].hits;
      setProducts(
        hits.map((hit) => ({
          id: hit.objectID,
          name: hit.productName,
          ...hit,
        }))
      );
    } catch (error) {
      console.error("Error searching products:", error);
    }
  };

  return (
    <Box>
      <TextField
        autoFocus
        margin="dense"
        id="search"
        label="Search products"
        type="text"
        fullWidth
        variant="outlined"
        value={searchQuery}
        onChange={handleInputChange}
      />
      <List>
        {products.map((product) => (
          <ListItem
            key={product.id}
            onClick={() => {
              onSelect(product);
              setSelectedProduct(product);
            }}
            selected={selectedProduct && selectedProduct.id === product.id}
          >
            <ListItemText primary={product.productName} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

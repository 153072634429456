import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import {
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  where,
  writeBatch,
} from "firebase/firestore";

import { DataGrid } from "@mui/x-data-grid";
import { InfoCard } from "../../components";
import { createCurrency } from "../../utils";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

const formatPercentage = (value) => `${(value * 100).toFixed(2)}%`;

function sanitizeDocumentId(id) {
  return id.replace(/\//g, "-").replace(/[^a-zA-Z0-9-_]/g, "_");
}

const CellWithBackground = ({ value, thresholds, children }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      width: "100%",
      height: "100%",
      backgroundColor:
        value > thresholds.high || value < thresholds.low ? "#ffdcdc" : "",
    }}
  >
    <Typography sx={{ fontSize: 14 }}>{children}</Typography>
  </Box>
);

export default function Products() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // New loading state variables
  const [isCountriesLoading, setIsCountriesLoading] = useState(false);
  const [isCitiesLoading, setIsCitiesLoading] = useState(false);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [unusedProducts, setUnusedProducts] = useState();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsCountriesLoading(true);
        // Fetch countries
        const countriesQuery = collectionGroup(db, "country");
        const countriesSnapshot = await getDocs(countriesQuery);
        const uniqueCountries = [
          ...new Set(countriesSnapshot.docs.map((doc) => doc.data().country)),
        ];
        setCountries(uniqueCountries);
        setIsCountriesLoading(false);

        setIsProductsLoading(true);
        // Fetch initial set of products
        const productsQuery = collection(db, "procurementInsights");
        const productsSnapshot = await getDocs(productsQuery);
        const fetchedProducts = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(fetchedProducts);

        const uniqueCategories = [
          ...new Set(fetchedProducts.map((p) => p.category)),
        ];
        setCategories(uniqueCategories);
        const uniqueSubCategories = [
          ...new Set(fetchedProducts.map((p) => p.subCategory)),
        ];
        setSubCategories(uniqueSubCategories);
        setIsProductsLoading(false);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (products?.length) {
      checkProductIsInUse();
    }
  }, [products]);

  async function checkProductIsInUse() {
    try {
      const productNames = products?.map((p) => p.productName);
      const results = await Promise.all(
        productNames.map(async (productName) => {
          const q = query(
            collection(db, "purchases"),
            where("productName", "==", productName)
          );
          const querySnapshot = await getDocs(q);

          return {
            productName,
            isUsed: !querySnapshot.empty,
          };
        })
      );

      const unusedProductsList = results.filter((result) => !result.isUsed);
      console.log("Unused products:", unusedProductsList);
      setUnusedProducts(unusedProductsList);
    } catch (error) {
      console.error("Error checking product usage:", error);
    } finally {
      console.log("done");
    }
  }

  const handleDeleteProduct = async (productName) => {
    try {
      // Find the document ID for the product
      const productDoc = products.find((p) => p.productName === productName);
      if (!productDoc) {
        console.error("Product not found:", productName);
        return;
      }
      console.log({ id: productDoc.id });
      // Delete the product from Firestore
      await deleteDoc(doc(db, "procurementInsights", productDoc.id));

      // Update local state
      setProducts(products.filter((p) => p.productName !== productName));
      setUnusedProducts(
        unusedProducts.filter((p) => p.productName !== productName)
      );

      console.log("Product deleted successfully:", productName);
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      if (selectedCountry) {
        setIsCitiesLoading(true);
        try {
          const citiesQuery = collectionGroup(db, "city");
          const citiesSnapshot = await getDocs(citiesQuery);
          const fetchedCities = citiesSnapshot.docs
            .filter((doc) => {
              return doc.ref.path.includes(
                `/country/${sanitizeDocumentId(selectedCountry)}/`
              );
            })
            .map((doc) => doc.data().city);
          const uniqueCities = [...new Set(fetchedCities)];
          setCities(uniqueCities);
        } catch (error) {
          console.error("Error fetching cities:", error);
        } finally {
          setIsCitiesLoading(false);
        }
      } else {
        setCities([]);
      }
    };

    fetchCities();
  }, [selectedCountry]);

  useEffect(() => {
    fetchFilteredProducts();
  }, [selectedCountry, selectedCity]);

  const fetchFilteredProducts = async () => {
    setIsProductsLoading(true);
    try {
      let fetchedProducts = [];
      if (selectedCountry || selectedCity) {
        let groupQuery;
        if (selectedCity) {
          groupQuery = collectionGroup(db, "city");
        } else {
          groupQuery = collectionGroup(db, "country");
        }
        const groupSnapshot = await getDocs(groupQuery);

        fetchedProducts = await Promise.all(
          groupSnapshot.docs
            .filter((doc) =>
              selectedCity
                ? doc.id === sanitizeDocumentId(selectedCity)
                : doc.id === sanitizeDocumentId(selectedCountry)
            )
            .map(async (doc) => {
              let productRef;
              if (selectedCity) {
                productRef = doc.ref.parent.parent.parent.parent;
              } else {
                productRef = doc.ref.parent.parent;
              }
              const productDoc = await getDoc(productRef);
              return {
                id: productDoc.id,
                ...productDoc.data(),
                country: selectedCountry || doc.id,
                city: selectedCity || null,
              };
            })
        );
      } else {
        const productsQuery = collection(db, "procurementInsights");
        const productsSnapshot = await getDocs(productsQuery);
        fetchedProducts = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      }
      setProducts(fetchedProducts);
      const uniqueCategories = [
        ...new Set(fetchedProducts.map((p) => p.category)),
      ];
      setCategories(uniqueCategories);
      const uniqueSubCategories = [
        ...new Set(fetchedProducts.map((p) => p.subCategory)),
      ];
      setSubCategories(uniqueSubCategories);
    } catch (error) {
      console.error("Error fetching filtered products:", error);
    } finally {
      setIsProductsLoading(false);
    }
  };

  const filteredProducts = useMemo(() => {
    return products.filter(
      (product) =>
        (!selectedCategory || product.category === selectedCategory) &&
        (!selectedSubCategory || product.subCategory === selectedSubCategory) &&
        product.productName.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [products, selectedCategory, selectedSubCategory, searchTerm]);

  const filteredSubCategories = useMemo(() => {
    if (!selectedCategory) return subCategories;
    return [
      ...new Set(
        products
          .filter((p) => p.category === selectedCategory)
          .map((p) => p.subCategory)
      ),
    ];
  }, [products, selectedCategory, subCategories]);

  const columns = useMemo(
    () => [
      {
        field: "productName",
        headerName: "Product Name",
        flex: 1,
        minWidth: 240,
      },
      {
        field: "lowPrice",
        headerName: "Low Price",
        type: "number",
        flex: 1,
        valueFormatter: (value) => createCurrency(value).format(),
      },
      {
        field: "highPrice",
        headerName: "High Price",
        type: "number",
        flex: 1,
        valueFormatter: (value) => createCurrency(value).format(),
      },

      {
        field: "priceDifference",
        headerName: "Price Difference",
        type: "number",
        flex: 1,
        // valueFormatter: ({ value }) =>
        //   value != null ? formatPercentage(value || 0) : "N/A",

        renderCell: ({ row }) => {
          const value = (row?.priceDifference || 0) * 100;
          const lowPrice = row?.lowPrice || 0;
          const highPrice = row?.highPrice || 0;
          const priceDifference = highPrice - lowPrice;
          const percentage = priceDifference / lowPrice;

          return (
            <CellWithBackground
              value={value}
              thresholds={{ high: 100, low: 5 }}
            >
              {formatPercentage(percentage || 0)}
            </CellWithBackground>
          );
        },
      },
      {
        field: "totalQuantity",
        headerName: "Total Quantity",
        type: "number",
        flex: 1,
        valueFormatter: (value) => value?.toFixed(2),
      },
      {
        field: "vendorCount",
        headerName: "Vendor Count",
        type: "number",
        flex: 1,
        renderCell: ({ row }) => {
          const value = row?.vendorCount || 0;
          return (
            <CellWithBackground
              value={value}
              thresholds={{ high: 100, low: 3 }}
            >
              {value}
            </CellWithBackground>
          );
        },
      },
      {
        field: "customerCount",
        headerName: "Customer Count",
        type: "number",
        flex: 1,
      },
    ],
    []
  );

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Typography variant="h4" mb={2}>
        Products
      </Typography>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={4}>
          <InfoCard title="Total Products" value={filteredProducts.length} />
        </Grid>
        <Grid item xs={4}>
          <InfoCard
            title="Products Under 3 Vendors"
            value={
              filteredProducts.filter(
                (product) => (product.vendorCount || 0) < 3
              ).length
            }
          />
        </Grid>
        <Grid item xs={4}>
          <InfoCard
            title="Products Price Diff < 5%"
            value={
              filteredProducts.filter(
                (product) => (product.priceDifference || 0) < 0.05
              ).length
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>Country</InputLabel>
            <Select
              value={selectedCountry}
              onChange={(e) => {
                setSelectedCountry(e.target.value);
                setSelectedCity("");
              }}
              label="Country"
              disabled={isCountriesLoading}
            >
              <MenuItem value="">All</MenuItem>
              {countries.map((country, i) => (
                <MenuItem key={i} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel>City</InputLabel>
            <Select
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
              label="City"
              disabled={!selectedCountry || isCitiesLoading}
            >
              <MenuItem value="">All</MenuItem>
              {cities.map((city) => (
                <MenuItem key={city} value={city}>
                  {city}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          label="Search Products"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "100%",
          }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Category</InputLabel>
          <Select
            value={selectedCategory}
            onChange={(e) => {
              setSelectedCategory(e.target.value);
              setSelectedSubCategory("");
            }}
            label="Category"
          >
            <MenuItem value="">All</MenuItem>
            {categories.map((category) => (
              <MenuItem key={category} value={category}>
                {category}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Sub Category</InputLabel>
          <Select
            value={selectedSubCategory}
            onChange={(e) => setSelectedSubCategory(e.target.value)}
            label="Sub Category"
          >
            <MenuItem value="">All</MenuItem>
            {filteredSubCategories.map((subCategory) => (
              <MenuItem key={subCategory} value={subCategory}>
                {subCategory}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {unusedProducts ? (
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h5" mb={2}>
            Unused Products
          </Typography>
          <Paper elevation={3} mb={4}>
            <List>
              {unusedProducts?.map((product) => (
                <ListItem
                  key={product.productName}
                  secondaryAction={
                    <Button
                      onClick={() => handleDeleteProduct(product.productName)}
                      color="error"
                    >
                      Delete
                    </Button>
                  }
                >
                  <ListItemText primary={product.productName} />
                </ListItem>
              ))}
              {unusedProducts?.length === 0 && (
                <Alert severity="success">No unused product in the list</Alert>
              )}
            </List>
          </Paper>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
          }}
          mb={2}
        >
          <CircularProgress />
        </Box>
      )}

      <Box sx={{ position: "relative", height: "75vh" }}>
        <DataGrid
          sx={{ height: "100%" }}
          rows={filteredProducts}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 25]}
          disableSelectionOnClick
          onRowClick={(params) => {
            if (selectedCity && selectedCountry) {
              navigate(
                `/purchase-analytics/${params.id}/country/${sanitizeDocumentId(
                  selectedCountry
                )}/city/${sanitizeDocumentId(selectedCity)}`
              );
            } else {
              navigate(`/purchase-analytics/${params.id}/country`);
            }
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "priceDifference", sort: "desc" }],
            },
          }}
        />
        {isProductsLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
}

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Delete, Save, Search } from "@mui/icons-material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import { db } from "../../firebase"; // Adjust the import path as necessary

const apiKey = "e811f65facc048d1bd4f0e28121cac07";
const endpoint = "https://api.bing.microsoft.com/v7.0/images/search";

const DropZone = ({ children, ...props }) => (
  <Box
    sx={{
      border: "2px dashed grey",
      borderRadius: 2,
      p: 2,
      textAlign: "center",
      cursor: "pointer",
    }}
    {...props}
  >
    {children}
  </Box>
);

export default function MergeProduct({ products, copyProduct, fetchProducts }) {
  const fileInputRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);

  const [imageSearchResults, setImageSearchResults] = useState([]);

  const [editFields, setEditFields] = useState({
    genericName: "",
    productName: "",
    taxRate: "",
    unit: "",
    unitWeight: "",
    category: "",
    subCategory: "",
    imgUrl: "",
    skipPurchaseTracking: false,
  });

  useEffect(() => {
    if (copyProduct) handleCopyProduct(copyProduct);
  }, [copyProduct]);

  function handleCopyProduct(product) {
    console.log({ product });
    const copyProduct = {
      genericName: product.genericName,
      productName: product.productName,
      taxRate: product.taxRate || 0,
      unit: product.unit,
      unitWeight: product.unitWeight,
      category: product.category,
      subCategory: product.subCategory,
      imgUrl: product.imgUrl,
      skipPurchaseTracking: product?.skipPurchaseTracking
        ? product?.skipPurchaseTracking
        : false,
    };
    setEditFields(copyProduct);
  }

  const handleEditFieldChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setEditFields({ ...editFields, [e.target.name]: value });
  };

  const searchImages = async () => {
    try {
      const response = await fetch(
        `${endpoint}?q=${encodeURIComponent(editFields.productName)}&count=6`,
        {
          headers: { "Ocp-Apim-Subscription-Key": apiKey },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setImageSearchResults(data.value);
    } catch (error) {
      console.error("Error searching for images:", error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      const storage = getStorage();
      const imageRef = ref(storage, `productImages/${file.name}`);
      await uploadBytes(imageRef, file);
      const downloadUrl = await getDownloadURL(imageRef);
      setEditFields((prev) => ({ ...prev, imgUrl: downloadUrl }));
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleFileDrop = async (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    if (file) {
      await uploadFile(file);
    }
  };

  async function saveChanges() {
    try {
      const updatePromises = products.map((id) =>
        updateDoc(doc(db, "products", id), editFields, { merge: true })
      );
      await Promise.all(updatePromises);
      fetchProducts();
      cleanFields();
    } catch (error) {
      console.error(error);
    }
  }

  function cleanFields() {
    setEditFields({
      genericName: "",
      productName: "",
      taxRate: "",
      unit: "",
      unitWeight: "",
      category: "",
      subCategory: "",
      imgUrl: "",
      skipPurchaseTracking: false,
    });
  }

  return (
    <Box sx={{ width: "30%", margin: 2 }}>
      <Box sx={{ mb: 2, width: "100%" }}>
        <Button
          variant="contained"
          startIcon={<Search />}
          onClick={searchImages}
          fullWidth
        >
          Search Images
        </Button>
        {imageSearchResults?.length > 0 && (
          <Button
            color="warning"
            variant="contained"
            startIcon={<Delete />}
            onClick={() => setImageSearchResults([])}
            fullWidth
            sx={{
              marginTop: 1,
            }}
          >
            Clear Images
          </Button>
        )}
      </Box>
      <Box sx={{ mb: 2 }}>
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          ref={fileInputRef}
          onChange={handleFileUpload}
        />
        <DropZone
          onDragOver={(e) => {
            e.preventDefault();
            setIsDragging(true);
          }}
          onDragLeave={(e) => {
            e.preventDefault();
            setIsDragging(false);
          }}
          onDrop={handleFileDrop}
          onClick={() => fileInputRef.current.click()}
          style={{
            backgroundColor: isDragging ? "rgba(0, 0, 0, 0.1)" : "transparent",
          }}
        >
          <Typography>
            Drag and drop an image here, or click to select a file
          </Typography>
        </DropZone>
      </Box>
      <Grid container spacing={1}>
        {imageSearchResults.map((image, index) => (
          <Grid item xs={4} key={index}>
            <img
              src={image.thumbnailUrl}
              alt={`Search result ${index + 1}`}
              style={{
                width: "100%",
                cursor: "pointer",
              }}
            />
          </Grid>
        ))}
      </Grid>
      <Typography variant="h5" fontWeight={"bold"} mt={2}>
        Edit Fields
      </Typography>
      <Typography variant="subtitle2" mb={2}>
        {products?.length} products selected
      </Typography>
      {Object.entries(editFields).map(([field, value]) =>
        field === "skipPurchaseTracking" ? (
          <FormControlLabel
            key={field}
            control={
              <Checkbox
                checked={value}
                onChange={handleEditFieldChange}
                name={field}
              />
            }
            label="Skip Purchase Tracking"
          />
        ) : (
          <TextField
            key={field}
            name={field}
            label={field.charAt(0).toUpperCase() + field.slice(1)}
            value={value || ""}
            onChange={handleEditFieldChange}
            fullWidth
            margin="normal"
          />
        )
      )}
      <Button
        variant="contained"
        startIcon={<Save />}
        onClick={saveChanges}
        fullWidth
      >
        Save Changes
      </Button>
    </Box>
  );
}

import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { SnackbarProvider as NotistackProvider } from "notistack";
import { useRef } from "react";

export default function SnackbarProvider({ children }) {
  const notistackRef = useRef();

  return (
    <NotistackProvider
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      ref={notistackRef}
      maxSnack={3}
      autoHideDuration={2000}
      action={(key) => (
        <IconButton
          color="inherit"
          size="small"
          onClick={() => notistackRef.current.closeSnackbar(key)}
        >
          <Close />
        </IconButton>
      )}
    >
      {children}
    </NotistackProvider>
  );
}

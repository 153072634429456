import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InfoCard } from "../../components";
import { fetchCompanies } from "../../scripts";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy_) {
  if (b[orderBy_] < a[orderBy_]) {
    return -1;
  }
  if (b[orderBy_] > a[orderBy_]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy_) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy_)
    : (a, b) => -descendingComparator(a, b, orderBy_);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "imgUrl", numeric: false, disablePadding: false, label: "Logo" },
  { id: "name", numeric: false, disablePadding: false, label: "Company Name" },
  { id: "country", numeric: false, disablePadding: false, label: "Country" },
  { id: "city", numeric: false, disablePadding: false, label: "City" },
];

export default function Customers() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { companyList, status, error } = useSelector(
    (state) => state.companies
  );
  const [order, setOrder] = useState("asc");
  const [orderBy_, setOrderBy] = useState("name");
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    if (!companyList?.length) {
      fetchCompanies({ dispatch });
    }
  }, []);

  useEffect(() => {
    if (companyList?.length) {
      const customers = companyList.filter(
        (company) => company.type === "customer"
      );
      setFilteredCompanies(customers);

      // Extract unique countries and cities
      const uniqueCountries = [
        ...new Set(customers.map((company) => company.country)),
      ];
      const uniqueCities = [
        ...new Set(customers.map((company) => company.city)),
      ];
      setCountries(uniqueCountries);
      setCities(uniqueCities);
    }
  }, [companyList]);

  useEffect(() => {
    if (companyList?.length) {
      const customers = companyList.filter(
        (company) => company.type === "customer"
      );
      const filtered = customers.filter(
        (company) =>
          company.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
          (selectedCountry === "" || company.country === selectedCountry) &&
          (selectedCity === "" || company.city === selectedCity)
      );
      setFilteredCompanies(filtered);
    }
  }, [companyList, searchTerm, selectedCountry, selectedCity]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy_ === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  if (status === "loading") {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (status === "failed") {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  if (!filteredCompanies || filteredCompanies.length === 0) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="info">No companies found.</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      <Typography variant="h4" className="mb-4">
        Customers
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          gap: 2,
          my: 3,
        }}
      >
        <InfoCard title="Count" value={filteredCompanies.length} />
        <InfoCard title="TBD" value={""} />
        <InfoCard title="TBD" value={""} />
      </Box>
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          sx={{
            width: "100%",
          }}
        />
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>Country</InputLabel>
          <Select
            value={selectedCountry}
            label="Country"
            onChange={(e) => setSelectedCountry(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {countries.map((country) => (
              <MenuItem key={country} value={country}>
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel>City</InputLabel>
          <Select
            value={selectedCity}
            label="City"
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {cities.map((city) => (
              <MenuItem key={city} value={city}>
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "left"}
                >
                  <TableSortLabel
                    active={orderBy_ === headCell.id}
                    direction={orderBy_ === headCell.id ? order : "asc"}
                    onClick={() => handleRequestSort(headCell.id)}
                  >
                    {headCell.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(filteredCompanies, getComparator(order, orderBy_)).map(
              (company) => (
                <TableRow
                  hover
                  key={company.id}
                  onClick={() => {
                    console.log(company?.id);
                    navigate("/purchase-analytics/customers/" + company?.id);
                  }}
                >
                  <TableCell>
                    {company?.imgUrl && (
                      <img
                        src={company.imgUrl}
                        alt="company logo"
                        width="40"
                        height="40"
                      />
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {company.name}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {company.country}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {company.city}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

import {
  AccountCircle as AccountIcon,
  AccountTreeRounded,
  ArticleRounded,
  Business as BusinessIcon,
  BusinessRounded,
  People as BuyersIcon,
  Category,
  ChevronLeft as ChevronLeftIcon,
  ExpandLess,
  ExpandMore,
  Home as HomeIcon,
  ExitToApp as LogoutIcon,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  PendingActionsRounded,
  PlaylistAddRounded,
  ShoppingCart as ProductsIcon,
  ReorderRounded,
  SellRounded,
  StoreRounded,
} from "@mui/icons-material";
import {
  Badge,
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  checkAnalyticsUnknownCategories,
  checkProcurementItemsToDelete,
  checkProcurementProductsConsistency,
  fetchApprovedInvoices,
  fetchInvoices,
  fetchPendingImages,
} from "../scripts";
import { useDispatch, useSelector } from "react-redux";

import { auth } from "../firebase";
import { signOut } from "firebase/auth";
import { styled } from "@mui/system";
import { useAuth } from "../hooks/useAuth";

const drawerWidth = 260;

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : theme.spacing(7),
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  "& .MuiDrawer-paper": {
    width: open ? drawerWidth : theme.spacing(7),
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const StyledListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== "isActive",
})(({ theme, isActive }) => ({
  marginBottom: theme.spacing(0.5),
  backgroundColor: isActive ? theme.palette.action.selected : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function VerticalMenu() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { invoices } = useSelector((state) => state.invoices);
  const {
    procurementItemsToDelete,
    procurementProductsToDelete,
    analyticsUnknownCategories,
  } = useSelector((state) => state.dbCheck);

  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [purchaseAnalyticsOpen, setPurchaseAnalyticsOpen] = useState(false);
  const [priceListOpen, setPriceListOpen] = useState(false);
  const [recipesOpen, setRecipesOpen] = useState(false);
  const [invoicesOpen, setInvoicesOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handlePurchaseAnalyticsToggle = () => {
    setPurchaseAnalyticsOpen(!purchaseAnalyticsOpen);
  };
  const handleRecipesToggle = () => {
    setRecipesOpen(!recipesOpen);
  };
  const handlePriceListToggle = () => {
    setPriceListOpen(!priceListOpen);
  };

  const handleInvoicesToggle = () => {
    setInvoicesOpen(!invoicesOpen);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchInvoices({ dispatch });
      fetchPendingImages({ dispatch });
      fetchApprovedInvoices({ dispatch });
      checkProcurementItemsToDelete({ dispatch });
      checkProcurementProductsConsistency({ dispatch });
      checkAnalyticsUnknownCategories({ dispatch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const menuItems = [
    {
      text: "Home",
      icon: (
        <Badge
          badgeContent={
            Object.keys(procurementItemsToDelete || {}).length +
            Object.keys(procurementProductsToDelete || {}).length +
            analyticsUnknownCategories?.length
          }
          color="error"
        >
          <HomeIcon />
        </Badge>
      ),
      path: "/home",
    },
    { text: "Companies", icon: <BusinessIcon />, path: "/companies" },
    { text: "Products", icon: <AccountTreeRounded />, path: "/products" },
  ];

  return (
    <StyledDrawer variant="permanent" open={open}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          p: 1,
        }}
      >
        <IconButton onClick={handleDrawerToggle}>
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
      <List>
        {menuItems.map((item, index) => (
          <Tooltip key={index} title={open ? "" : item.text} placement="right">
            <StyledListItem
              component={Link}
              to={item.path}
              isActive={location.pathname === item.path}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              {open && <ListItemText primary={item.text} />}
            </StyledListItem>
          </Tooltip>
        ))}
        <Tooltip title={open ? "" : "Invoices"} placement="right">
          <StyledListItem onClick={handleInvoicesToggle}>
            <ListItemIcon>
              <Badge badgeContent={invoices?.length} color="error">
                <ArticleRounded />
              </Badge>
            </ListItemIcon>
            {open && (
              <>
                <ListItemText primary="Invoices" />
                {invoicesOpen ? <ExpandLess /> : <ExpandMore />}
              </>
            )}
          </StyledListItem>
        </Tooltip>
        <Collapse in={invoicesOpen && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              component={Link}
              to="/invoices"
              isActive={location.pathname === "/invoices"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <PendingActionsRounded />
              </ListItemIcon>
              <ListItemText primary="Pending Invoices" />
            </StyledListItem>
          </List>
          <List component="div" disablePadding>
            <StyledListItem
              component={Link}
              to="/company-invoices"
              isActive={location.pathname === "/company-invoices"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <StoreRounded />
              </ListItemIcon>
              <ListItemText primary="Company Invoices" />
            </StyledListItem>
          </List>
        </Collapse>
        <Tooltip title={open ? "" : "Purchase Analytics"} placement="right">
          <StyledListItem onClick={handlePurchaseAnalyticsToggle}>
            <ListItemIcon>
              <SellRounded />
            </ListItemIcon>
            {open && (
              <>
                <ListItemText primary="Purchase Analytics" />
                {purchaseAnalyticsOpen ? <ExpandLess /> : <ExpandMore />}
              </>
            )}
          </StyledListItem>
        </Tooltip>
        <Collapse
          in={purchaseAnalyticsOpen && open}
          timeout="auto"
          unmountOnExit
        >
          <List component="div" disablePadding>
            <StyledListItem
              component={Link}
              to="/purchase-analytics"
              isActive={location.pathname === "/purchase-analytics"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <ProductsIcon />
              </ListItemIcon>
              <ListItemText primary="Products" />
            </StyledListItem>
            <StyledListItem
              component={Link}
              to="/purchase-analytics/vendors"
              isActive={location.pathname === "/purchase-analytics/vendors"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <BusinessRounded />
              </ListItemIcon>
              <ListItemText primary="Vendors" />
            </StyledListItem>
            <StyledListItem
              component={Link}
              to="/purchase-analytics/customers"
              isActive={location.pathname === "/purchase-analytics/customers"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <BuyersIcon />
              </ListItemIcon>
              <ListItemText primary="Customers" />
            </StyledListItem>
          </List>
        </Collapse>
        <Tooltip title={open ? "" : "Recipes"} placement="right">
          <StyledListItem onClick={handleRecipesToggle}>
            <ListItemIcon>
              <Category />
            </ListItemIcon>
            {open && (
              <>
                <ListItemText primary="Recipes" />
                {recipesOpen ? <ExpandLess /> : <ExpandMore />}
              </>
            )}
          </StyledListItem>
        </Tooltip>
        <Collapse in={recipesOpen && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              component={Link}
              to="/recipes"
              isActive={location.pathname === "/recipes"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <PlaylistAddRounded />
              </ListItemIcon>
              <ListItemText primary="Recipes" />
            </StyledListItem>
          </List>
        </Collapse>

        <Tooltip title={open ? "" : "Price List"} placement="right">
          <StyledListItem onClick={handlePriceListToggle}>
            <ListItemIcon>
              <ReorderRounded />
            </ListItemIcon>
            {open && (
              <>
                <ListItemText primary="Price List" />
                {priceListOpen ? <ExpandLess /> : <ExpandMore />}
              </>
            )}
          </StyledListItem>
        </Tooltip>
        <Collapse in={priceListOpen && open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <StyledListItem
              component={Link}
              to="/price-list/create"
              isActive={location.pathname === "/price-list/create"}
              sx={{ pl: 4 }}
            >
              <ListItemIcon>
                <PlaylistAddRounded />
              </ListItemIcon>
              <ListItemText primary="Add" />
            </StyledListItem>
          </List>
        </Collapse>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <List>
        <Tooltip title={open ? "" : "Account"} placement="right">
          <StyledListItem>
            <ListItemIcon>
              <AccountIcon />
            </ListItemIcon>
            {open && <ListItemText primary="Account" />}
          </StyledListItem>
        </Tooltip>
        <Tooltip title={open ? "" : "Logout"} placement="right">
          <StyledListItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            {open && <ListItemText primary="Logout" />}
          </StyledListItem>
        </Tooltip>
      </List>
    </StyledDrawer>
  );
}

import {
  AllProducts,
  City,
  Companies,
  CompanyInvoice,
  Countries,
  CreateAdmin,
  CreatePriceList,
  Customer,
  Customers,
  EditCompany,
  Home,
  Invoice,
  Invoices,
  Login,
  Products,
  Recipe,
  Recipes,
  Vendor,
  Vendors,
} from "./scenes";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import Menu from "./components/Menu";
import { Provider } from "react-redux";
import React from "react";
import { createTheme } from "@mui/material/styles";
import { store } from "./redux/store";
import { useAuth } from "./hooks/useAuth";

const theme = createTheme(); // You can customize this theme

function App() {
  const { user, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Box sx={{ display: "flex" }}>
            {user && <Menu />}
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
              <Routes>
                <Route
                  path="/"
                  element={user ? <Navigate to="/home" replace /> : <Login />}
                />
                <Route
                  path="/create-admin"
                  element={user ? <CreateAdmin /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/home"
                  element={user ? <Home /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/companies"
                  element={user ? <Companies /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/company/:companyId"
                  element={user ? <EditCompany /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/products"
                  element={user ? <AllProducts /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/purchase-analytics"
                  element={user ? <Products /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/purchase-analytics/:productId/country"
                  element={user ? <Countries /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/purchase-analytics/:productId/country/:countryId/city/:cityName"
                  element={user ? <City /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/purchase-analytics/vendors"
                  element={user ? <Vendors /> : <Navigate to="/" replace />}
                />

                <Route
                  path="/purchase-analytics/customers"
                  element={user ? <Customers /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/purchase-analytics/vendors/:supplierId"
                  element={user ? <Vendor /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/purchase-analytics/customers/:customerId"
                  element={user ? <Customer /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/price-list/create"
                  element={
                    user ? <CreatePriceList /> : <Navigate to="/" replace />
                  }
                />
                <Route
                  path="/recipes"
                  element={user ? <Recipes /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/recipes/:customerId/:recipeId"
                  element={user ? <Recipe /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/invoices"
                  element={user ? <Invoices /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/company-invoices"
                  element={
                    user ? <CompanyInvoice /> : <Navigate to="/" replace />
                  }
                />
                <Route
                  path="/invoice/:id"
                  element={user ? <Invoice /> : <Navigate to="/" replace />}
                />
              </Routes>
            </Box>
          </Box>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

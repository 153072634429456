import { collectionGroup, getDocs, query, where } from "firebase/firestore";

import { db } from "../firebase";
import { setAnalyticsUnknownCategories } from "../redux";

export async function checkAnalyticsUnknownCategories({ dispatch }) {
  try {
    const q = query(
      collectionGroup(db, "categories"),
      where("originalName", "==", "")
    );
    const querySnapshot = await getDocs(q);

    // Use forEach instead of map for side effects
    let paths = [];
    querySnapshot.forEach((doc) => {
      if (doc.ref.path.includes("analytics")) {
        paths.push(
          `https://console.firebase.google.com/project/teklif-app-35e18/firestore/databases/-default-/data/~2F${encodeURIComponent(
            doc.ref.path
          )}`
        );
      }
    });

    console.log(paths);
    dispatch(setAnalyticsUnknownCategories(paths));
  } catch (error) {
    console.error("Error fetching user products:", error);
    throw error; // Re-throw the error for the caller to handle
  }
}

import React, { useEffect, useState } from "react";

export default function InvoicePreview({ img, contentType, sx, onClick }) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const imgElement = new Image();
    imgElement.src = img;
    imgElement.onload = () => setLoading(false);
    if (contentType === "application/pdf") {
      setLoading(false);
    }
  }, [img, contentType]);

  if (!img)
    return (
      <div
        style={{
          ...sx,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>{"selectImageToPreview"}</p>
      </div>
    );

  if (loading) {
    return (
      <div
        style={{
          ...sx,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>{"loading"}</p>
      </div>
    );
  }

  if (contentType === "image/png") {
    return (
      <div style={sx} onClick={onClick}>
        <img
          src={img}
          alt="Invoice preview"
          style={{
            ...sx,
          }}
        />
      </div>
    );
  }

  if (contentType === "application/pdf") {
    return (
      <div style={sx} onClick={onClick}>
        <object
          data={img}
          type={contentType}
          style={{
            ...sx,
            width: "100%",
            height: "100%",
            minHeight: "500px", // Adjust as needed
            border: "none",
          }}
        >
          <p>
            Unable to display PDF.{" "}
            <a href={img} target="_blank" rel="noopener noreferrer">
              Download PDF
            </a>
          </p>
        </object>
      </div>
    );
  }

  return (
    <div style={sx} onClick={onClick}>
      <embed
        src={img}
        type={contentType}
        style={{
          ...sx,
          maxWidth: "100%",
          display: "block",
          objectFit: "fill",
          borderRadius: "15px",
        }}
      />
    </div>
  );
}

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function ProductInvoices({ productName, city }) {
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (productName) {
      fetchProductInvoices(productName);
    }
  }, [productName]);

  async function fetchProductInvoices(productName) {
    try {
      let q = query(
        collection(db, "purchases"),
        where("productName", "==", productName),
        orderBy("date.seconds", "desc")
      );

      if (city) {
        q = query(
          collection(db, "purchases"),
          where("productName", "==", productName),
          where("city", "==", city),
          orderBy("date.seconds", "desc")
        );
      }

      const invoiceSnap = await getDocs(q);
      const invoiceData = invoiceSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log({ invoiceData });

      setInvoices(invoiceData);
    } catch (error) {
      console.error(error);
    }
  }

  const handleRowClick = (event, invoiceId) => {
    if (event.button === 1 || event.ctrlKey || event.metaKey) {
      window.open(`/invoice/${invoiceId}`, "_blank", "noopener,noreferrer");
      event.preventDefault();
    } else {
      navigate(`/invoice/${invoiceId}`);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {productName} invoices
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Vendor</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow
                key={invoice.id}
                onClick={(event) => handleRowClick(event, invoice?.invoiceId)}
                style={{ cursor: "pointer" }}
              >
                <TableCell>{invoice.vendorName}</TableCell>
                <TableCell>{invoice.customerName}</TableCell>
                <TableCell>
                  {new Date(invoice?.date?.seconds * 1000).toLocaleDateString()}
                </TableCell>
                <TableCell>{invoice?.price?.toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

import {
  Alert,
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

import { DataGrid } from "@mui/x-data-grid";
import { ProductInvoices } from "../../components";
import { createCurrency } from "../../utils";
import { db } from "../../firebase";

const SummaryCard = ({ title, value, unit }) => (
  <Card>
    <CardContent>
      <Typography color="textSecondary" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" component="div">
        {typeof value === "number" ? value.toFixed(2) : value} {unit}
      </Typography>
    </CardContent>
  </Card>
);

const BuyerSellerGrid = ({ data }) => {
  const navigate = useNavigate();

  const columns = [
    { field: "id", headerName: "Buyer ID", width: 130 },
    {
      field: "price",
      headerName: "Last Purchase (TL)",
      type: "number",
      width: 150,
      valueFormatter: (value) => createCurrency(value).format(),
    },
    {
      field: "priceDifference",
      headerName: "Price Difference (%)",
      type: "number",
      width: 150,
      valueFormatter: (value) => `${((value || 0) * 100)?.toFixed(2)}%`,
    },
    {
      field: "totalQuantity",
      headerName: "Total Quantity",
      type: "number",
      width: 130,
      valueFormatter: (value) => value?.toFixed(2),
    },
    {
      field: "totalQuantityWeight",
      headerName: "Total Weight",
      type: "number",
      width: 130,
      valueFormatter: (value) => `${(value || 0)?.toFixed(2)}%`,
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        onRowClick={(params) =>
          navigate(`/purchase-analytics/customers/${params?.id}`)
        }
      />
    </div>
  );
};

const SellerGrid = ({ data }) => {
  const navigate = useNavigate();
  const columns = [
    {
      field: "sellerName",
      headerName: "Vendor Name",
      width: 130,
    },
    {
      field: "price",
      headerName: "Last Price (TL)",
      type: "number",
      width: 150,
      valueFormatter: (value) => createCurrency(value).format(),
    },
    {
      field: "priceDifference",
      headerName: "Price Difference (%)",
      type: "number",
      width: 150,
      valueFormatter: (value) => `${((value || 0) * 100)?.toFixed(2)}%`,
    },
    {
      field: "totalQuantity",
      headerName: "Total Quantity",
      type: "number",
      width: 130,
      valueFormatter: (value) => value?.toFixed(2),
    },
    {
      field: "totalQuantityWeight",
      headerName: "Total Weight",
      type: "number",
      width: 130,
      valueFormatter: (value) => `${(value || 0)?.toFixed(2)}%`,
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        disableSelectionOnClick
        onRowClick={(params) =>
          navigate(`/purchase-analytics/vendors/${params?.id}`)
        }
      />
    </div>
  );
};

const CityPage = () => {
  const { productId, countryId, cityName } = useParams();
  const [cityData, setCityData] = useState(null);
  const [buyers, setBuyers] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCityData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const cityDocRef = doc(
          db,
          `procurementInsights/${productId}/country/${countryId}/city/${cityName}`
        );
        const cityDocSnap = await getDoc(cityDocRef);

        if (cityDocSnap.exists()) {
          setCityData(cityDocSnap.data());

          // Fetch buyers and sellers
          const buyersCollectionRef = collection(cityDocRef, "buyers");
          const sellersCollectionRef = collection(cityDocRef, "sellers");

          const [buyersSnapshot, sellersSnapshot] = await Promise.all([
            getDocs(buyersCollectionRef),
            getDocs(sellersCollectionRef),
          ]);

          setBuyers(
            buyersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          );
          setSellers(
            sellersSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
          );
        } else {
          setError("No data found for this city.");
        }
      } catch (error) {
        console.error("Error fetching city data:", error);
        setError("Failed to fetch city data. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchCityData();
  }, [productId, countryId, cityName]);

  if (isLoading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!cityData)
    return <Alert severity="info">No data available for this city.</Alert>;

  return (
    <Box sx={{ p: 4 }}>
      {/* <Typography variant="h4" sx={{ mb: 4 }}>
        {cityName} - {cityData.productName} Analytics
      </Typography> */}
      <Breadcrumbs
        separator="›"
        aria-label="breadcrumb"
        sx={{
          mb: 2,
          // .css-1wuw8dw-MuiBreadcrumbs-separator
          "& .MuiBreadcrumbs-separator": {
            fontSize: "2.25rem",
            marginX: "1rem",
          },
        }}
      >
        <Link
          component={RouterLink}
          to="/purchase-analytics"
          sx={{
            fontSize: "2rem",
          }}
        >
          Products
        </Link>
        <Link
          component={RouterLink}
          to={`/purchase-analytics/${productId}/country`}
          sx={{
            fontSize: "2rem",
          }}
        >
          {countryId}
        </Link>
        <Typography
          // to={`/purchase-analytics/country/${countryId}/city/${cityName}`}
          sx={{
            fontSize: "2rem",
          }}
        >
          {cityName}
        </Typography>
        <Typography
          sx={{
            fontSize: "2rem",
          }}
        >
          {cityData.productName}
        </Typography>
      </Breadcrumbs>

      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <SummaryCard title="Low Price" value={cityData.lowPrice} unit="TL" />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard
            title="High Price"
            value={cityData.highPrice}
            unit="TL"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard
            title="Price Difference"
            value={cityData.priceDifference * 100}
            unit="%"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <SummaryCard
            title="Total Quantity"
            value={cityData.totalQuantity}
            unit={cityData.unit}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Buyers
          </Typography>
          <BuyerSellerGrid data={buyers} isBuyer={true} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Sellers
          </Typography>
          <SellerGrid data={sellers} isBuyer={false} />
        </Grid>
      </Grid>
      <ProductInvoices productName={cityData?.productName} city={cityName} />
    </Box>
  );
};

export default CityPage;

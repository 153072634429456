import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { EditNoteRounded } from "@mui/icons-material";
import { EditProductDialog } from "./index";
import { db } from "../../firebase";

const unitOptions = ["kg", "demet", "adet", "lt"]; // Add more options as needed

const InvoiceProducts = ({
  invoice,
  setAlerts,
  setInvoice,
  calculateInvoice,
}) => {
  const [localProducts, setLocalProducts] = useState([]);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [invoiceUpdated, setInvoiceUpdated] = useState(false);

  useEffect(() => {
    if (
      invoice &&
      invoice.items &&
      localProducts.length > 0 &&
      !invoiceUpdated
    ) {
      const updatedItems = invoice.items.map((item) => {
        const matchingProduct = localProducts.find(
          (product) => product.id === item.productId
        );
        if (matchingProduct) {
          return {
            ...item,
            taxRate: matchingProduct.taxRate || 0,
            imgUrl: matchingProduct.imgUrl || "",
            unit: matchingProduct.unit || "",
            unitWeight: matchingProduct.unitWeight || 1,
            productName: matchingProduct.productName || "",
          };
        }
        return item;
      });

      const updatedInvoice = {
        ...invoice,
        items: updatedItems,
      };

      setInvoice(calculateInvoice(updatedInvoice));
      setInvoiceUpdated(true);
      console.log({ updatedItems });
    }
  }, [invoice, localProducts, setInvoice, invoiceUpdated]);

  const fetchRelatedProducts = useCallback(
    async (productName) => {
      try {
        const q = query(
          collection(db, "products"),
          where("productName", "==", productName)
        );
        const querySnapshot = await getDocs(q);
        const products = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRelatedProducts(products);
      } catch (error) {
        console.error("Error fetching related products:", error);
        setAlerts((prev) => ({
          ...prev,
          fetchError: {
            severity: "error",
            message: "Failed to fetch related products",
          },
        }));
      }
    },
    [setAlerts]
  );

  const handleEditClick = async (product) => {
    setSelectedProduct(product);
    await fetchRelatedProducts(product.productName);
    setEditDialogOpen(true);
  };

  const handleEditDialogClose = (updated) => {
    setEditDialogOpen(false);
    setSelectedProduct(null);
    setRelatedProducts([]);
    if (updated) {
      // Refresh the products list if updates were made
      getProducts(invoice.items);
    }
  };

  const getProducts = useCallback(
    async (items) => {
      try {
        const productsData = await Promise.all(
          items.map(async (item) => {
            const productRef = doc(db, "products", item?.productId || "");
            const productDoc = await getDoc(productRef);
            return productDoc.exists()
              ? { id: productDoc.id, ...productDoc.data() }
              : null;
          })
        );

        const filteredProducts = productsData.filter(Boolean);
        setLocalProducts(filteredProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },
    [setAlerts]
  );

  useEffect(() => {
    if (invoice?.items?.length) {
      getProducts(invoice.items);
    }
  }, [invoice, getProducts]);

  const handleInputChange = (index, key, value) => {
    setLocalProducts((prev) =>
      prev.map((product, i) =>
        i === index ? { ...product, [key]: value } : product
      )
    );
  };

  const updateProduct = useCallback(
    async (index, key, value) => {
      const product = localProducts[index];
      try {
        const q = query(
          collection(db, "products"),
          where("productName", "==", product.productName)
        );
        const querySnapshot = await getDocs(q);
        const updatePromises = querySnapshot.docs.map((doc) =>
          updateDoc(doc.ref, { [key]: value })
        );
        querySnapshot.docs.map((doc) => console.log(doc.data()));
        await Promise.all(updatePromises);
      } catch (error) {
        console.error("Error updating product:", error);
        setAlerts((prev) => ({
          ...prev,
          updateError: {
            severity: "error",
            message: `Failed to update ${key} for ${product.productName}`,
          },
        }));
      }
    },
    [localProducts, setAlerts]
  );

  return (
    <Box sx={{ mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Products
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Img</TableCell>
              <TableCell>GenericName</TableCell>
              <TableCell>ProductName</TableCell>
              <TableCell>TaxRate</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>UnitWeight</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>SubCategory</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {localProducts.map((product, index) => (
              <TableRow
                key={index}
                sx={{
                  backgroundColor: product?.skipPurchaseTracking
                    ? "#fdeded"
                    : "",
                }}
              >
                <TableCell>
                  <Avatar
                    src={product.imgUrl || "/placeholder-image.jpg"}
                    alt={product.productName}
                    variant="square"
                    sx={{ width: 40, height: 40 }}
                  />
                </TableCell>
                <TableCell>{product.genericName || ""}</TableCell>
                <TableCell>{product.productName || ""}</TableCell>
                <TableCell>
                  <TextField
                    value={product.taxRate || 0}
                    type="number"
                    size="small"
                    inputProps={{ step: "1", min: 0, max: 100 }}
                    onChange={(e) =>
                      handleInputChange(index, "taxRate", e.target.value)
                    }
                    onBlur={() =>
                      updateProduct(index, "taxRate", product.taxRate)
                    }
                  />
                </TableCell>
                <TableCell>
                  <Select
                    value={product.unit || ""}
                    size="small"
                    onChange={(e) =>
                      updateProduct(index, "unit", e.target.value)
                    }
                  >
                    {unitOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <TextField
                    value={product.unitWeight || 0}
                    size="small"
                    onChange={(e) =>
                      handleInputChange(index, "unitWeight", e.target.value)
                    }
                    onBlur={() =>
                      updateProduct(index, "unitWeight", product.unitWeight)
                    }
                  />
                </TableCell>
                <TableCell>{product.category || ""}</TableCell>
                <TableCell>{product.subCategory || ""}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEditClick(product)}>
                    <EditNoteRounded />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditProductDialog
        open={editDialogOpen}
        onClose={handleEditDialogClose}
        product={selectedProduct}
        relatedProducts={relatedProducts}
        setAlerts={setAlerts}
      />
    </Box>
  );
};

export default InvoiceProducts;

import { collection, doc, getDocs, updateDoc } from "firebase/firestore";

import { db } from "../firebase";

export async function syncProducts() {
  try {
    const purchasesRef = collection(db, "purchases");
    const querySnapshot = await getDocs(purchasesRef);

    const offersRef = collection(db, "offers");
    const offersSnapshot = await getDocs(offersRef);

    const purchaseProductIds = querySnapshot.docs.map(
      (doc) => doc.data()?.productId
    );
    const offerProductIds = offersSnapshot.docs.map(
      (doc) => doc.data()?.productId
    );

    const uniqueProductIds = [
      ...new Set(purchaseProductIds),
      ...new Set(offerProductIds),
    ];

    uniqueProductIds.forEach((id, index) => {
      const productRef = doc(db, "products", id);
      updateDoc(
        productRef,
        {
          inUse: true,
        },
        { merge: true }
      );
      console.log({ id }, `last ${uniqueProductIds?.length - index} item`);
    });
  } catch (error) {
    console.error("Error syncing products:", error);
  }
}

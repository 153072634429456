import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";
import { setApprovedInvoices } from "../redux";

export function fetchApprovedInvoices({ dispatch }) {
  const invoicesQuery = query(
    collection(db, "cleanedInvoiceData"),
    where("status", "==", "admin-approved"),
    orderBy("date.seconds", "desc")
  );

  const unsubscribeInvoices = onSnapshot(
    invoicesQuery,
    (querySnapshot) => {
      const fetchedInvoices = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(setApprovedInvoices(fetchedInvoices));
    },
    (error) => {
      console.error("Error listening to invoices:", error);
    }
  );

  return () => {
    unsubscribeInvoices();
  };
}

import { collection, getDocs } from "firebase/firestore";

import { db } from "../firebase";
import { setCompanyList } from "../redux/companiesSlice";

export async function fetchCompanies({ dispatch }) {
  try {
    const companies = await getDocs(collection(db, "companyDetails"));
    const companyList = companies.docs.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    dispatch(setCompanyList(companyList));
  } catch (error) {
    console.log(error);
  }
}
